import { Link } from "react-router-dom";
import { Grid,Card,Button, ButtonGroup,useMediaQuery,Loading,Text,Spacer,Image,Table,Textarea,Collapse } from '@geist-ui/core'

export default function Error() {
    return (
        <div>
         <Spacer h={12}/>
            <Grid.Container justify="center" >
            <Grid xs={23} sm={23} md={8} direction="column">    
         
            <h1>Oops! Такої сторінки не існує</h1>
            <Link to='/'>На головну</Link>
            
            </Grid>
        
            </Grid.Container>   
            <Spacer h={24}/>
        </div>
    )
}