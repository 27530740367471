import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
// import Header from './Header';
import { Grid,Link,Card,Image,Spacer,Text,Divider} from '@geist-ui/core'
import parnters from './img/partners2.png';


let test = {
  // border:"1px solid grey",
  color:'grey'
}


function Footer() {


  return (
    <div className="App">
      <Spacer h={4}/>
      <Divider />
      

      
      <Grid.Container justify="center" alignItems="center"  style={{background:'#fff'}}>
          
         <Grid xs={22} sm={7} md={7} direction="column" style={{marginTop:'20px',padding:'10px'}}>
         <Link target="_blank" href="https://courses.kse.ua/home/pp-specialist">
         
          <Card style={{opacity:.7}}>
            <Image src="https://cep.kse.ua/assets/img/prog2.png"
             height="200px" width="300px" draggable={false} />
            <Text h4 mb={0}>"Фахівець з публічних закупівель"</Text>
            <Text type="secondary" small>*в умовах воєнного часу</Text>
          </Card>
            </Link>
          </Grid>
          
          <Grid xs={22} sm={7} md={7} direction="column" style={{marginTop:'20px',padding:'10px'}}>
          <Link target="_blank" href="https://courses.kse.ua/home/procurement-management">
          <Card style={{opacity:.7}}>
            <Image src="https://cep.kse.ua/assets/img/prog1.png"
             height="200px" width="300px" draggable={false} />
            <Text h4 mb={0}>"Закупівельний менеджмент"</Text>
            <Text type="secondary" small>*в умовах воєнного часу</Text>
          </Card>
            </Link>
          </Grid>
          
          <Grid xs={22} sm={7} md={4} direction="column" style={{marginTop:'0px',padding:'0px'}}>
            <Text small>
             Центр вдосконалення закупівель Kyiv School of Economics.  
             Київ, вул. Шпака, 3, Email: cep@kse.org.ua
            </Text>
            <Spacer h={1}/>
            <Text b small>Інші проекти СЕР:</Text>
            <Spacer h={1}/>
            <Text small><Link underline href="https://cep.kse.ua/">Сайт cep.kse.ua</Link></Text>
            <Text small><Link underline href="https://cpvtool.kse.ua/">CPV-tool</Link></Text>
            <Text small><Link underline href="https://cep.kse.ua/osoba">Уповноважена особа</Link>
            </Text>    
            
          </Grid>
          {/* <Grid xs={0} sm={1} md={1} direction="column" style={{marginTop:'20px',padding:'10px'}}>
          
          </Grid> */}
          
          {/* <Grid xs={22} sm={7} md={5} direction="column" style={{marginTop:'20px',padding:'10px'}}>
            <Text b small>Інші проекти СЕР</Text>
            <Text small><Link underline href="https://cep.kse.ua/">Сайт cep.kse.ua</Link></Text>
            <Text small><Link underline href="https://cpvtool.kse.ua/">CPV-tool</Link></Text>
            <Text small><Link underline href="https://cep.kse.ua/osoba">Уповноважена особа</Link>
            </Text>    
          </Grid> */}
          
        </Grid.Container>
      
      
        <Spacer h={2}/>

    </div>
  );
}

export default Footer;
