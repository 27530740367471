import React from 'react';
// import Header from './Header';
import { getUser,getToken } from './Utils/Common';
import { Radio,Textarea,Divider,Select, Image,Dot,Checkbox,Modal,Text,Input,Spacer,Button,Fieldset,Table } from '@geist-ui/core'
import uniqid from 'uniqid';
// import queryString from 'query-string';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';

// let test = {border:"1px solid grey"}


function ResumeAdd(props) {

  const user = getUser();
  const token = getToken();
  
  const[agree,setAgree] = React.useState(false)
  const [image, setImage] = React.useState({ preview: '', data: '' })
  const [status, setStatus] = React.useState('')


  const { control, register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      type: 'ВАКАНСІЯ',
      dateCreate: new Date(),
      documentId:uniqid(),
      user:user,
      approved:false,
      logo:''

    }
  });
  
  const onSubmit = async data => {
    data.responsibility = data.responsibility?.replace(/\n+/g,'\n')
    data.zone = data.zone?.replace(/\n+/g,'\n')
    data.requirements = data.requirements?.replace(/\n+/g,'\n')
    data.day = data.day?.replace(/\n+/g,'\n')
    data.expectation = data.expectation?.replace(/\n+/g,'\n')
    data.motivation = data.motivation?.replace(/\n+/g,'\n')
    data.offer = data.offer?.replace(/\n+/g,'\n')
    
 
    if (!/image/.test(image.data.type)) 
    {
      setStatus("Має бути файл у форматі png, jpeg, jpg, svg")
      return
    }
    
    goToTop()
    setModal(true)
    
    props.setNewvacancy({data,image:image.data})
  };


  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };


  const [modal, setModal] = React.useState(false)
  const closeHandler = (event) => {
    setModal(false)
  }


  // const handleImage = async (documentId) => {
  //   // e.preventDefault()
  //   let formData = new FormData()
  //   formData.append('file', image.data)
  //   formData.append('user', image.user)
  //   formData.append('documentId', documentId)
  //   const response = await fetch('http://localhost:5000/upload', {
  //     method: 'POST',
  //     body: formData,
  //   })
  //   if (response) setStatus(response.statusText)
  // }

  const handleFileImage = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      user:user
    }
    setImage(img)
  }
    

  
  // console.log('vacancyAdd:',image)

  
  
  
  return (
    <>
         {/* <Fieldset.Title>Заповніть всі поля</Fieldset.Title> */}
          
          <Fieldset.Subtitle>
          <Text h3>Назва посади</Text>
          <Input type="secondary" width='95%' {...register("position", { required: true })} 
           placeholder="Назва посади" name="position"  /><br />
          {errors.position ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>Назва організації</Text>
          <Input type="secondary" width='95%' {...register("org", { required: true })} 
           placeholder="Назва організації" name="org"  /><br />
          {errors.org ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          <Text h3>Сектор закупівель:</Text>
          <Controller
            name="sector"
            control={control}
            rules={{ required: true }}
            style={{paddingRight:'50px'}}
            render={({ field }) => 
              <Radio.Group {...field} ref={null} >
                <Radio value="Публічний">
                  <Text type="secondary" small>Публічний</Text>
                </Radio>
                <Radio value="Комерційний">
                  <Text type="secondary" small>Комерційний</Text>
                </Radio>
                <Radio value="Некомерційний">
                  <Text type="secondary" small>Некомерційний</Text>
                  <Radio.Description><Text small>Громадські організації, благодійні фонди тощо</Text></Radio.Description>
                </Radio>
                <Radio value="Інше">
                  <Text type="secondary" small>Інше</Text>
                </Radio>
              </Radio.Group>
            }
            />
          {errors.sector ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>


          <Text h3>Сфера діяльності</Text>
          <Controller
            name="industry"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
            <Select style={{border:'1px solid #555'}} {...field} multiple width="88%" placeholder="Оберіть один або декілька варіантів" >
                <Select.Option value="Переробка сировини (добувна промисловість, сільське господарство, металургія, виробництво будівельних матеріалів)">Переробка сировини (добувна промисловість, сільське господарство, металургія, виробництво будівельних матеріалів)</Select.Option>
                  <Select.Option value="Машинобудування">Машинобудування</Select.Option>
                  <Select.Option value="Споживчі товари та торгівля">Споживчі товари та торгівля</Select.Option>
                  <Select.Option value="Послуги (готелі, ресторани, туризм)">Послуги (готелі, ресторани, туризм)</Select.Option>
                  <Select.Option value="Послуги для громадян (охорона здоров'я, фінансові та страхові послуги)">Послуги для громадян (охорона здоров'я, фінансові та страхові послуги)</Select.Option>
                  <Select.Option value="Інфраструктура (будівництво, ІТ, транспорт, постачання електроенергії чи газу)">Інфраструктура (будівництво, ІТ, транспорт, постачання електроенергії чи газу)</Select.Option>
                  <Select.Option value="Державні установи">Державні установи</Select.Option>
                  <Select.Option value="Благодійні фонди та громадські організації">Благодійні фонди та громадські організації</Select.Option>
                  <Select.Option value="Інше">Інше</Select.Option>
                </Select>
                }
          /><br />
          {errors.industry ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>Область</Text>
          <Controller
            name="region"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
            <Select style={{border:'1px solid #555'}} {...field} width="88%" placeholder="Виберіть область">
              {
                region.map((d,index)=>(
                  <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                ))
              }            
            </Select>
                }
          /><br />
           {errors.region ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          <Text h3>Населений пункт</Text>
          <Input type="secondary" width='95%' placeholder="Вкажіть населений пункт" name="city"
          {...register("city", { required: true })} /><br />
           {errors.city ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>


          <Text h3>Заробітна плата</Text>
          <p style={{marginRight:'40px'}}>
          Приклади: конкретна сума, гілка від до, за результатами співбесіди.
          </p>
          <Input type="secondary" width='95%' placeholder="Заробітна плата" name="salary"
          {...register("salary", { required: true })} /><br />
           {errors.salary ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>


          <Text h3>Вид зайнятості/ Формат роботи</Text>
          <Controller
            name="format"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
            <Select style={{border:'1px solid #555'}} {...field} multiple width="88%" placeholder="Оберіть один або декілька варіантів" >
                  <Select.Option value="100% офлайн">100% офлайн</Select.Option>
                  <Select.Option value="змішаний формат (офіс+віддалено)">змішаний формат (офіс+віддалено)</Select.Option>
                  <Select.Option value="100% віддалений">100% віддалений</Select.Option>
              </Select>
              }
          /><br />
          {errors.format ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>До якої дати актуальна вакансія</Text>
          <Input type="secondary" width='95%' placeholder="YYYY-MM-DD" name="deadline"/><br />
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
         
          <Text h3>Обов’язки</Text>
          <Textarea type="secondary" width='90%' placeholder="Обов’язки" name="responsibility"
          {...register("responsibility", { required: true })} /><br />
           {errors.responsibility ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>


          <Text h3>Як виглядає день співробітника</Text>
          <Textarea type="secondary" width='90%' placeholder="Вказати" name="day"
          {...register("day")} /><br />
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
           

          <Text h3>Які очікуються результати за перший місяць та рік</Text>
          <Textarea type="secondary" width='90%' placeholder="Вказати" name="expectation"
          {...register("expectation")} /><br />
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          
          <Text h3>Зона відповідальності</Text>
          <p style={{marginRight:'20px'}}>
          За який напрям відповідає, які процеси має вести та інше
          </p>
          <Textarea type="secondary" width='90%' placeholder="Вкажіть" name="zone"
          {...register("zone", { required: true })} /><br />
          {errors.zone ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          
          
          <Text h3>Вимоги до кандидата</Text>
          <p style={{marginRight:'40px'}}>
          Досвід, вимоги до сертифікації або ліцензій, знання мов, рівень освіти, та інші
          </p>
          <Textarea type="secondary" width='90%' placeholder="Вкажіть" name="requirements"
          {...register("requirements", { required: true })} /><br />
          {errors.requirements ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          
          <Text h3>Що пропонує компанія</Text>
          <p style={{marginRight:'40px'}}>
          Умови роботи, соціальний пакет, навчання співробітника, менторинг керівниками, та інше          </p>
          <Textarea type="secondary" width='90%' placeholder="Вкажіть" name="offer"
          {...register("offer", { required: true })} /><br />
          {errors.offer ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
           
          <Text h3>Які документи ви очікуєте на відкуг на цю вакансію</Text>
          <Textarea type="secondary" width='90%' placeholder="Вкажіть" name="motivation"
          {...register("motivation")} /><br />
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
      
          <Text h3>Логотип компанії</Text>   
          
          {image.preview && <Image width="80%" src={image.preview} />}
          <Spacer h={1}/>
          <input type='file' name='file' onChange={handleFileImage}></input>
          <Text type="error" small>{status}</Text>
          {/* <Spacer h={1}/>      
          <Text type="warning"  small>Це поле має бути обов'язково заповнене</Text> */}
          <Spacer h={1}/>         
          <p style={{marginRight:'40px'}}>
          ...або додайте посилання на логотип. Зазвичай на сайті вашої організації вказаний логотип. Посилання на нього: права кнопка миші "Копіювати адресу зображення" (обов'язково у форматі .png або .jpeg або svg). </p>
          <Input width='95%' {...register("logo", { pattern: /png|jpeg|jpg|svg/i })} 
           placeholder="Наприклад: https://cep.kse.ua/assets/logoblack.png" name="logo"  /><br /> 
           
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
            
          
          <Text h3>Ім'я, прізвище та посада контактної особи, яка розміщує вакансію</Text>
          <Input type="secondary" width='95%' {...register("name", { required: true })} 
           placeholder="Ім'я та прізвище контактної особи" name="name"  /><br />
          {errors.name ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
         
         
          <Text h3>Ваш контактний номер телефону</Text>
          <p style={{marginRight:'40px'}}>
          Ця інформація потрібна лише Центру вдосконалення закупівель для зв'язку з вами і не буде відображатися в опублікованій вакансії</p>
          <Input type="secondary" width='95%' {...register("mobile", { required: true })} 
           placeholder="Ім'я та прізвище контактної особи" name="mobile"  /><br />
          {errors.mobile ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
            
            
          {<Button style={{width:'90%',marginTop:'30px'}} type="secondary" 
          onClick={handleSubmit(onSubmit)} 
          // onClick={onSubmit} 
          disabled={!agree||false} >Подати</Button>}
        
          <Modal visible={modal} onClose={closeHandler}>
            <Modal.Title>Вакансію додано!</Modal.Title>
            {/* <Modal.Subtitle>Резюме додано!</Modal.Subtitle> */}
            <Modal.Content>
              <p>Залишилося ще зовсім трохи, Ваша вакансія буде <Dot type="warning" /> перевірена Центром вдосконалення закупівель, і ми одразу Вам повідомимо про результат. Тоді вакансію зможуть переглядати на сайті усі відвідувачі</p>
            </Modal.Content>
            <Modal.Action passive onClick={() => setModal(false)}>Ok</Modal.Action>
            {/* <Modal.Action>Submit</Modal.Action> */}
          </Modal>




          <Spacer h={1}/>
          <Fieldset.Footer style={{marginLeft:'-20.5px'}}>
            <Checkbox onChange={value => {
              setAgree(value.target.checked)
            }} checked={false}>
              <Text small>
                Згода на обробку і публікацію, оприлюднення всіх вказаних даних
              </Text>
            </Checkbox>
          </Fieldset.Footer>
          <Spacer h={1}/>
          </Fieldset.Subtitle>
     

    </>
  );
}

let region = [
  "Вінницька",
  "Волинська",
  "Дніпропетровська",
  "Донецька",
  "Житомирська",
  "Закарпатська",
  "Запорізька",
  "Івано-Франківська",
  "Київська",
  "Кіровоградська",
  "Луганська",
  "Львівська",
  "Миколаївська",
  "Одеська",
  "Полтавська",
  "Рівненська",
  "Сумська",
  "Тернопільська",
  "Харківська",
  "Херсонська",
  "Хмельницька",
  "Черкаська",
  "Чернівецька",
  "Чернігівська"
]


let corses = [
  "Закупівельний менеджмент",					
  "Менеджер із закупівель",						
  "Фахівець з публічних закупівель",						
  "Підготовка до тестування УО",						
  "Програма професіоналізації закупівель",						
  "Тренінг для тренерів",						
  "Зелені публічні закупівлі"
]

export default ResumeAdd;
