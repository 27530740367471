import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import Header from './Header';
import { Grid,Divider, Spacer,Text } from '@geist-ui/core'


let test = {
// border:"1px solid grey"
lineHeight:1.5
}


function About() {
  
  // console.log('About:')


  return (
    <div className="App">
     <Header/>
      <Spacer h={2}/>
      

      <Grid.Container  justify="center" style={test}>
        <Grid xs={22} sm={18} md={14} style={test} direction="column">

        <Text h1>
        ЗАКУПІВЕЛЬНІ ВАКАНСІЇ
        </Text>
   
        
        <Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як знайти вакансію</Divider>
<Spacer h={1}/>
        
<Text>1. Переходьте у розділ "Вакансії"<br></br> 
2. Скористайтеся фільтрами (сектор, регіон)<br></br> 
3. Ознайомтеся з описом позиції та вимогами до кандидатів<br></br> 
4. Підготуйте резюме та супровідний лист відповідно до вимог компанії, що зазначені в описі вакансії<br></br> 
5. Відправте ваші документи на мейл контактної особи від компанії.
</Text>
<Text>6. Отримуйте зворотній зв’язок від компанії по вашій аплікації. 
 </Text>


<Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як розмістити вакансію</Divider>
<Spacer h={1}/>

<Text p>1. Переходьте у розділ “Вакансії”.
<br></br> 
2. Натисніть на опцію "Увійти"<br></br> 
3. Підтвердіть вашу електронну пошту (на вказану при реєстрації пошту прийде лист із відповідним посиланням).<br></br> 
4. Перед заповненням форми для розміщення вакансії вам потрібно мати мінімально необхідну інформацію, а саме:</Text>
<Text p>а. Назва посади<br></br> 
b. Короткий опис про компанію (2-3 речення)<br></br> 
c. Короткий опис очікувань від позиції (які плануються задачі або цілі для позиції)<br></br> 
d. Опис вимог до кандидата (Мінімально необхідні + які бажано мати)<br></br> 
e. Опис формату роботи + що надає співробітникам компанія (відпустки, премії, медичне страхування, фінансує навчання та інше).<br></br> 
f. Ім’я, прізвище та електронна пошта контактної особи для відправки резюме й супровідного листа </Text>
<Text p>Важливо! Це мінімальна інформація, яку ми просимо від вас для розміщення. Ви можете додавати додаткові блоки, які необхідні саме вам. Тут ви знайдете приклад опису вакансії з коментарями, який ви можете використовувати в своїй роботі.
</Text>
<Text p>5. В особистому кабінеті натискайте на "Подати вакансію" та заповнюйте відповідні поля.<br></br> 
6. Після збереження система присвоїть статус "Очікування". Наш спеціаліст у робочі дні протягом 24 годин обробить вашу заявку. <br></br> 
7. Після обробки заявки статус зміниться на "Опубліковано". Про що ви отримаєте лист на вказаний вами мейл. <br></br> 
8. Тепер ваша вакансія опублікована! Ми радимо відповідати якомога швидше аплікантам. Вчасна та якісна комунікація створює позитивний бренд вашої команди!</Text>


<Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як додати логотип організації</Divider>
<Spacer h={1}/>

<Text p>Щоб додати посилання на логотип вашої організації, перейдіть на сайт вашої компанії, наведіть курсор на логотип, розміщений, як правило, у лівому верхньому кутку екрану та натисніть один раз на праву кнопку мишки. Оберіть опцію “Відкрити зображення в новій вкладці”. Перейдіть на щойно відкриту вкладку з зображенням логотипу і скопіюйте посилання. 

 </Text>

 <Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як Видалити Вакансію
</Divider>
<Spacer h={1}/>
Для видалення вакансії увійдіть в особистий кабінет CEP Jobs. Навпроти поля із назвою вакансії натисніть на іконку "Видалити". Ваша вакансія видалена.


<Spacer h={4}/>

<Text h1>
РЕЗЮМЕ ЗАКУПІВЕЛЬНИКІВ
</Text>
   

<Text p>У розділі Резюме публікуються CV закупівельників, які перебувають у пошуку роботи в закупівлях. </Text>

<Text p>У кожному резюме вже вказані контактні дані спеціаліста, а також є позначка про успішне завершення принаймні однієї з навчальних програм Центру вдосконалення закупівель.
</Text>
<Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як знайти резюме:</Divider>
<Spacer h={1}/>


<Text p>1. Переходьте у розділ Резюме<br></br> 
2. Скористайтеся фільтрами (рівень, сектор, регіон)<br></br> 
3. Ознайомтеся з досвідом кандидатів<br></br> 
4. Сконтактуйте з релевантними кандидатами, використовуючи контакти, розміщені в резюме</Text>
<Text p>5. Отримайте відгук на вашу пропозицію з працевлаштування від кандидата.</Text>


<Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як розмістити резюме</Divider>
<Spacer h={1}/>

<Text p>1. Переходьте у розділ Резюме<br></br> 
2. Натискайте на опцію "Створити кабінет"<br></br> 
3. Підтвердіть вашу електронну пошту (на вказану при реєстрації пошту прийде лист із відповідним посиланням).<br></br> 
4. Для створення резюме ви маєте надати необхідну інформацію про власний досвід:</Text>
<Text p>а) Рівень<br></br> 
b) Вид зайнятості (повна, неповна, віддалений формат, готовність до релокації тощо)<br></br> 
c) Населений пункт<br></br> 
d) Досвід роботи, навички та інструменти, знання мов<br></br> 
e) Освіта та навчальні програми, успішно завершені у Центрі вдосконалення закупівель KSE<br></br> 
f) Контактна інформація для можливості потенційних роботодавців зв'язатися з вами<br></br> 
g) Згода на обробку та публікацію, оприлюднення всіх вказаних даних </Text>
<Text p> Це мінімальна інформація, яку ми просимо від вас для розміщення. Ви можете додавати додаткові блоки, які необхідні саме вам. Тут ви знайдете приклад опису резюме з коментарями, який ви можете використовувати в своїй роботі.
</Text>
<Text p>5. В особистому кабінеті натискайте на "Подати резюме" та заповнюйте відповідні поля.<br></br> 
6. Після збереження система присвоїть статус "Очікування". Наш спеціаліст у робочі дні протягом 24 годин обробить вашу заявку. <br></br> 
7. Після обробки заявки статус зміниться на "Опубліковано". Про що ви отримаєте лист на вказаний вами мейл. <br></br> 
8. Тепер ваше резюме опубліковано! Ми радимо відповідати якомога швидше на пропозиції потенційних роботодавців. Вчасна та якісна комунікація підсилює ваш особистий професійний бренд!
</Text>

<Spacer h={2}/>
<Divider style={{background:'#3fd4adA'}}>Як Видалити Резюме
</Divider>
<Spacer h={1}/>
Для видалення резюме увійдіть в особистий кабінет CEP Jobs. Навпроти поля із назвою резюме натисніть на іконку "Видалити". Ваше резюме видалене.


        
        
<Spacer h={2}/>


        </Grid>
       
        </Grid.Container>


    </div>
  );
}

export default About;
