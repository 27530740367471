import React from 'react';
import Header from './Header';
import axios from 'axios';
import { Grid,Card, Spacer,Text,Description,Loading,Image,Select,Button,Pagination,
  CssBaseline, GeistProvider, Themes,Divider} from '@geist-ui/core'
import { NavLink } from 'react-router-dom';
// import { ArrowRightCircle } from '@geist-ui/icons'
import Award from '@geist-ui/icons/award'
import MapPin from '@geist-ui/icons/mapPin'
import { useForm, Controller } from "react-hook-form";

const myTheme1 = Themes.createFromLight({
  type: 'coolTheme',
  palette: {
    success: '#1d91c0',
  },
})

let selection = {
  padding:'10px',
  borderRight:'1px solid #eee'
}
let selection2 = {
  padding:'0px 5px 0px 0px',
}


function Allvacancy() {

  const [vacancy, setVacancy] = React.useState([]);
  const [page, setPage] = React.useState([1]);
  const [error, setError] = React.useState(false);

  const { control, handleSubmit,getValues } = useForm({
    defaultValues: {
      type: 'ВАКАНСІЯ',
      position:"Будь який",
      sector:"Будь який",
      region:"Вся Україна",

    }
  });
  const onSubmit = d => {
    // console.log('!',d);
    getTotalVacancy(page,d.type,d.sector,d.region)
  };

  React.useEffect(() => {
    getTotalVacancy(page,getValues().type,getValues().sector,getValues().region)
    window.scrollTo(0, 0);
  }, [page]);


  const getTotalVacancy = (page,type,sector,region) => {
    axios.get(`https://cepjobs.vercel.app/api/allvacancy?page=${page}&type=${type}&sector=${sector}&region=${region}`)
    .then(response => {
      response.data.length==0?setError(true):setError(false)
      setVacancy(response.data);
      // console.log('all',response.data)
    }).catch(error => {
    });
  }

  
  // console.log('setVacancy:',vacancy)


  return (
    <>
      <Header/>
      
      <div style={{ background:'#3fd4ad'}}>
      
      <Spacer h={4.2}/>
      
      <Grid.Container justify="center" style={selection}>
        
        {/* <Grid xs={23} sm={23} md={2} style={selection}>
          <Text small type="secondary" style={selection2}>Фільтри відборів</Text>
        </Grid> */}


        <Grid xs={23} sm={12} md={4} style={selection} direction="column">
          <Text small style={selection2}>Сектор:</Text>
          <Controller
                name="sector"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => 
                <Select style={{border:'1px solid #555'}} {...field} placeholder="Будь який" >
                  <Select.Option value="Будь який">Будь який</Select.Option>
                  <Select.Option divider />
                  <Select.Option value="Публічний">Публічний</Select.Option>
                  <Select.Option value="Комерційний">Комерційний</Select.Option>
                  <Select.Option value="Некомерційний">Некомерційний</Select.Option>
                </Select>
                }
          />
        </Grid>

        <Grid xs={23} sm={12} md={4} style={selection} direction="column">
          <Text small style={selection2}>Регіон:</Text>
          <Controller
              name="region"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => 
              <Select style={{border:'1px solid #555'}} {...field}  placeholder="Вся Україна">
                {
                  region.map((d,index)=>(
                    <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                  ))
                }            
              </Select>
              }
            />
        </Grid>

        <Grid xs={23} sm={12} md={3} style={{padding:'5px'}}>
          <Button style={{marginTop:'25px'}} scale={0.75} auto type="secondary" onClick={handleSubmit(onSubmit)}>Відібрати</Button>
        </Grid>
        
      </Grid.Container>
      
      
    </div>





      
      <Grid.Container  justify="center" style={{background:'#ece7f2'}}>
        <Text type="secondary">Вакансії</Text>
      </Grid.Container>

      
      
      <Grid.Container justify="center" style={{background:'#ece7f2',fontFamily: "Roboto"}}>
        {error?<>Нічого не знайдено<Spacer h={24}/></>:vacancy.length>0?
          vacancy.map((d,index)=>(
            <Grid key={index} index={index} xs={24} sm={12} md={7}>
              <Card shadow width="95%" style={{margin:'10px',paddingRight:'25px', 
              background: "#fff"}}>
                
                {/* https://kse.ua/wp-content/uploads/2018/12/kse400.jpg */}
                
                <div>
                  {d.logo!=''?d.logo.includes('http')&&(d.logo.includes('png')||d.logo.includes('jpg')||d.logo.includes('jpeg')||d.logo.includes('svg'))&&<Image style={{paddingTop:'10px'}} width='40%' src={d.logo} className="images"/>:d.image?.data?<Image style={{paddingTop:'0px',width: "350px",height: "60px"}} src={"data:image/jpeg;base64, "+d.image?.data}/>:''}
                </div>
                
                <Text type="secondary" style={{fontSize:'10px'}}>{d.type}</Text>
                
                <NavLink to={`/vacancy?id=${d.documentId}`}>
                  <Text h5 style={{color:'#1d91c0',textDecoration: "underline #1d91c0",minHeight:"80px",fontSize:'110%'}} my={0}>
                  {d.position}
                  </Text>                
                </NavLink>
                
                <Divider />
                
                <Grid.Container style={{paddingTop:'15px'}}>
                  <Grid xs={12} sm={12} md={14}>
                    <Description title="Компанія:" content= {d.org} />
                  </Grid>
                  <Grid xs={12} sm={12} md={1}>
                    </Grid>
                  <Grid xs={12} sm={12} md={9}>
                    {/* <Description title="Сектор:" content= {d.sector} /> */}
                    <Description title="Місце:" content={d.region+", "+d.city} />
                  </Grid>
                </Grid.Container>

                {/* <Grid.Container style={{paddingTop:'15px'}}>
                  <Grid xs={12} sm={12} md={14}>
                    <Description title="Дійсна до:" content= {d.deadline} />
                  </Grid>
                  <Grid xs={12} sm={12} md={10}>
                    <Description title="Місце:" content={d.region+", "+d.city} />
                  </Grid>
                </Grid.Container> */}
                
              </Card>
            </Grid>
        
            ))
            :[1,2,3,4,5,6,7,8,9].map((d,index)=>(
              <Grid key={index} index={index} xs={24} sm={12} md={7} justify="center">
                <Card width="95%" height="350px"  style={{margin:'10px'}}>
                  <Loading />
                </Card>
              </Grid>
               ))
            }
             
      </Grid.Container>

      

      <Spacer h={2}/>

      <Grid.Container  justify="center">
      <Spacer h={2}/>
      <GeistProvider themes={[myTheme1]} themeType="coolTheme">
        <CssBaseline />
        <Pagination count={5} initialPage={1} onChange={(val) => {
          // window.history.pushState("object or string", "Page Title", `?page=${page}&position=${getValues().position}`);
          setPage(val)
        }}/>
      </GeistProvider>

      </Grid.Container>
      <Spacer h={2}/>
      
    </>
  );
}

let region = [
  "Вся Україна",
  "Вінницька",
  "Волинська",
  "Дніпропетровська",
  "Донецька",
  "Житомирська",
  "Закарпатська",
  "Запорізька",
  "Івано-Франківська",
  "Київська",
  "Кіровоградська",
  "Луганська",
  "Львівська",
  "Миколаївська",
  "Одеська",
  "Полтавська",
  "Рівненська",
  "Сумська",
  "Тернопільська",
  "Харківська",
  "Херсонська",
  "Хмельницька",
  "Черкаська",
  "Чернівецька",
  "Чернігівська"
]

export default Allvacancy;
