import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import Header from './Header';
import { Grid,Dot, Spacer,Text,Description,Divider,Loading,Tooltip } from '@geist-ui/core'
import axios from 'axios';
import QuestionCircle from '@geist-ui/icons/questionCircle'
// import * as d3 from "d3";
import Award from '@geist-ui/icons/award'
import TrendingUp from '@geist-ui/icons/trendingUp'
import Layers from '@geist-ui/icons/layers'
import Clock from '@geist-ui/icons/clock'
import MapPin from '@geist-ui/icons/mapPin'
import Mail from '@geist-ui/icons/mail'
import { CreditCard } from '@geist-ui/icons'
import Briefcase from '@geist-ui/icons/briefcase'

let test = {
  // border:"1px solid red"
}

let cep = {background:"#b2edde",padding:"20px"}

let p_wrap = {
  "whiteSpace": "pre-line",
  fontWeight:'normal',
  fontSize:"16px",
  lineHeight:1.5
}

function Resume() {
  const [resume, setResume] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [load, setLoad] = React.useState(true);

  const params = new URLSearchParams(window.location.search)
  let documentId = params.get('id')



  function oneresume(){
    axios.get(`https://cepjobs.vercel.app/api/oneresume?documentId=${documentId}`)
    .then(response => {
      // console.log('resume ',response)
      response.data.forEach(d => {
        try {
          d.date = d.dateCreate.replace(/T.+/,'')
        } catch (error) {
          d.date = d.dateCreate
        }
      });
      setResume(response.data[0]);
      

      setLoad(false)
      window.scrollTo(0, 0);
      setError('Такої сторінки не існує :(')
    }).catch(error => {});
  }

  React.useEffect(() => {
    oneresume()
  }, []);


  React.useEffect(() => {
    let timer = setTimeout(() =>axios.get(`https://cepjobs.vercel.app/api/views?documentId=${documentId}`)
    .then(response => {
      // console.log('resume views',response.data)
    }).catch(error => {}), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  // console.log('Resume:',resume)


  return (
    <div className="App">
     <Header/>
      
    {load&& 
    
    <Grid.Container justify="center" style={{background:'#ece7f2',padding:'20px'}}>
    <Spacer h={8}/>
    <Loading />
    <Spacer h={20}/>
    </Grid.Container>
    }
    
     
      <Grid.Container justify="center" style={{background:'#ece7f2',padding:'20px'}}>
          {resume?
            <> 
  
            <Grid xs={24} sm={20} md={14} style={{border:'1px solid #ddd',background:'#fff',padding:'20px',}} direction="column">
            
            <Grid.Container justify="center" style={test} >
              <Grid xs={24} sm={24} md={20} style={test} direction="column">
                <Text h2><Dot type={resume.approved?"success":'warning'} />{resume.name}</Text>
              </Grid>
              <Grid xs={24} sm={24} md={4} style={test} justify="center" direction="column">
              <Text small type="secondary">Дата створення: {resume.date}</Text>
              </Grid>
            </Grid.Container>
            
                
                

                {resume.role&&<Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                    <Briefcase color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Роль в закупівлях:" content={
                      resume.role.map((d,index)=>(
                      <div key={index} index={index}>
                        {d}
                      </div>
                    ))
                   } />
                </Grid.Container>}
                
                
                {resume.salary&&<Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                    <CreditCard color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Бажана зп:" content={resume.salary+' тис грн'} />
                </Grid.Container>}
                
                
                <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                    <TrendingUp color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Позиція:" content={<>{resume.position}<Tooltip text={resume.position=="Початковий рівень"?<Text small>Досвід менше 1 року (молодший спеціаліст, молодший баєр, та інші)</Text>:resume.position=="Просунутий рівень"?<Text small>Досвід від 1 року (категорійни менеджер, менеджер із закупівель, та інші)</Text>:<Text small>Досвід на керівних посадах від 1 року (керівник відділу закупівель, СРО, керівник відділу планування та інші)</Text>}><QuestionCircle size={14}/></Tooltip></>} />
                </Grid.Container>
                
               
                <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                    <Layers color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Сектор:" content={resume.sector} />
                </Grid.Container>
                

                <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                    <Clock color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Формат:" content={
                    resume.format.map((d,index)=>(
                      <div key={index} index={index}>
                        {d}
                      </div>
                    ))
                   } />
                </Grid.Container>
                
                
                <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px',marginBottom:'20px'}}>
                <MapPin color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Область, місце:" content={resume.region+", "+resume.city} />
                </Grid.Container>
                
    
    
    
                
                
              
              <Grid xs={24} sm={24} md={24} justify="center" style={cep} direction="row">
                  {
                    resume.cep&&resume.cep.length>0&&
                      <Description title="Курси Центру вдосконалення закупівель:" 
                      content= 
                        {resume.cep.map((d2,index2)=>(
                        <div  key={index2} index={index2}>
                          <Award color="#3fd4ad"/>{d2}
                        </div>
                      ))}
                      />
                    }
              </Grid>

     
              {resume.experience&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px',margin:'5px'}} direction="column"><Description title="Досвід:" content={<Text style={p_wrap}>{resume.experience}</Text>} /></Grid>}
              
              {resume.skills&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px'}} direction="column"><Divider h={1}/><Description title="Досягнення:" content={<Text style={p_wrap}>{resume.skills}</Text>} /></Grid>}
              
              
              {resume.achievement&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px'}} direction="column"><Divider h={1}/><Description title="Досягнення:" content={<Text style={p_wrap}>{resume.achievement}</Text>} /></Grid>}

              {resume.education&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px'}} direction="column"><Divider h={1}/><Description title="Освіта:" content={<Text style={p_wrap}>{resume.education}</Text>} /></Grid>}

              {resume.languages&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px'}} direction="column"><Divider h={1}/><Description title="Знання мов:" content={<Text style={p_wrap}>{resume.languages}</Text>} /></Grid>}

              {resume.volunteer&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px'}} direction="column"><Divider h={1}/><Description title="Волонтерська діяльність:" content={<Text style={p_wrap}>{resume.volunteer}</Text>} /></Grid>}

              {resume.hobby&&<Grid xs={24} sm={24} md={24} style={{background:'#fff',padding:'10px'}} direction="column"><Divider h={1}/><Description title="Захоплення:" content={<Text style={p_wrap}>{resume.hobby}</Text>} /></Grid>}
        
              <Grid xs={24} sm={24} md={24} style={{background:'#eee',padding:'10px',margin:'20px'}} direction="row">
                <Mail />
                <Spacer w={.5}/>
                <Description title="Пошта кандидата:" content={resume.user} />
              </Grid>

          </Grid>
        </>
        :<><Text h3>{error}</Text><Spacer h={24}/></>}
      
      </Grid.Container>

     


    </div>
  );
}

export default Resume;
