import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import { Grid,Dot, Spacer,Text,Description,Divider,Loading,Image } from '@geist-ui/core'
import axios from 'axios';
// import * as d3 from "d3";
import Award from '@geist-ui/icons/award'
import Activity from '@geist-ui/icons/activity'
import Layers from '@geist-ui/icons/layers'
import Clock from '@geist-ui/icons/clock'
import MapPin from '@geist-ui/icons/mapPin'
import Mail from '@geist-ui/icons/mail'
import Home from '@geist-ui/icons/home'
import Header from './Header';

// let test = {borderRight:"1px solid #333",padding:"5px",background:"#fff"}
// let cep = {background:"#b2edde",padding:"20px"}

let p_wrap = {
  "whiteSpace": "pre-line",
  fontWeight:'normal',
  fontSize:"16px",
  lineHeight:1.6,
  fontFamily: "Roboto"
}

function Vacancy() {
  const [vacancy, setVacancy] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [load, setLoad] = React.useState(true);
  // const [logo, setLogo] = React.useState(null);


  const params = new URLSearchParams(window.location.search)
  let documentId = params.get('id')



  function onevacancy(){
    axios.get(`https://cepjobs.vercel.app/api/onevacancy?documentId=${documentId}`)
    .then(response => {
      response.data.forEach(d => {
      try {
        d.date = d.dateCreate.replace(/T.+/,'')
      } catch (error) {
        d.date = d.dateCreate
      }
        
      });
      setVacancy(response.data[0]);
      // if(response.data[0].logo==''){
      //   logo_search(response.data[0].user)
      // }
      setLoad(false)
      window.scrollTo(0, 0);
      setError('Такої сторінки не існує :(')
    }).catch(error => {});
  }

  React.useEffect(() => {
    onevacancy()
  }, []);

  
  

  
  React.useEffect(() => {
    let timer = setTimeout(() =>axios.get(`https://cepjobs.vercel.app/api/views?documentId=${documentId}`)
    .then(response => {
      // console.log('resume views',response.data)
    }).catch(error => {}), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  
//  console.log('Vacancy:',vacancy)



  return (
    <div className="App">
    
    <Header/>
      
    {load&&  
    <Grid.Container justify="center" style={{background:'#f7fbff',padding:'10px'}}>
    <Spacer h={4}/>
    <Loading />
    <Spacer h={24}/>
    </Grid.Container>
    }
    
     
      <Grid.Container justify="center" style={{background:'#f7fbff',padding:'20px'}}>
          {vacancy?
            <> 

            <Grid xs={24} sm={20} md={14} style={{border:'1px solid #ddd',background:'#fff',padding:'20px'}} direction="column">
                
            {vacancy.logo!=''?vacancy.logo.includes('http')&&(vacancy.logo.includes('png')||vacancy.logo.includes('jpg')||vacancy.logo.includes('jpeg')||vacancy.logo.includes('svg'))&&<Image style={{paddingTop:'0px'}} width="80%" height='80%' src={vacancy.logo}/>:vacancy.image?.data?<Image style={{paddingTop:'0px'}} width="80%" height='80%' src={"data:image/jpeg;base64, "+vacancy.image?.data}/>:''}
            
                <Text h1><Dot type={!vacancy.approved||vacancy.approved=='false'?"warning":'success'} />{vacancy.position}</Text>
                
                <Text small type="secondary">Вакансія актуальна до: {vacancy.deadline}</Text>

                <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'30px'}}>
                  <Home color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Організація:" content={vacancy.org} />
                </Grid.Container>
                
                 <Grid.Container  justify="flex-start" direction="row" style={{marginTop:'20px'}}>
                    <Activity color="#3fd4ad"/>
                    <Spacer w={.5}/>
                    <Description title="Галузь:" content={
                      vacancy.industry.map((d,index)=>(
                        <div key={index} index={index}>
                          {d}
                        </div>
                      ))
                    } />
                  </Grid.Container>

                  
                  <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                  <Layers color="#3fd4ad"/>
                  <Spacer w={.5}/>
                  <Description title="Сектор:" content={vacancy.sector} />
                  </Grid.Container>
                
                
                  <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px'}}>
                  <Clock color="#3fd4ad"/>
                  <Spacer w={.5}/>
                  <Description title="Формат:" content={
                    vacancy.format.map((d,index)=>(
                      <div key={index} index={index}>
                        {d}
                      </div>
                    ))
                   } />
                  </Grid.Container>
                  
                  
                  <Grid.Container  justify="flex-start" direction="row" style=  {{marginTop:'20px',marginBottom:'20px'}}>
                  <MapPin color="#3fd4ad"/>
                  <Spacer w={.5}/>
                  <Description title="Область, місце:" content={vacancy.region+", "+vacancy.city} />
                  </Grid.Container>
                  
                  
                
              
            {vacancy.salary&&vacancy.salary.length>1&&<>
              <Divider h={1}/>
              <Text b small>ЗАРОБІТНА ПЛАТА:</Text>
              <Text style={p_wrap}>{vacancy.salary}</Text>
            </>}
            
            {vacancy.responsibility&&vacancy.responsibility.length>1&&<>
              <Divider h={1}/>
              <Text b small>ОБОВ’ЯЗКИ:</Text>
              <Text style={p_wrap}>{vacancy.responsibility}</Text>
            </>}
            
            {vacancy.day&&vacancy.day.length>1&&<>
              <Divider h={1}/>
              <Text b small>ДЕНЬ СПІВРОБІТНИКА:</Text>
              <Text style={p_wrap}>{vacancy.day}</Text>
            </>}
            
            {vacancy.expectation&&vacancy.expectation.length>1&&<>
              <Divider h={1}/>
              <Text b small>ОЧІКУВАНІ РЕЗУЛЬТАТИ:</Text>
              <Text style={p_wrap}>{vacancy.expectation}</Text>
            </>}
            
            
            {vacancy.zone&&vacancy.zone.length>1&&<>
              <Divider h={1}/>
              <Text b small>ЗОНА ВІДПОВІДАЛЬНОСТІ:</Text>
              <Text style={p_wrap}>{vacancy.zone}</Text>
            </>}
            
           
            {vacancy.requirements&&vacancy.requirements.length>1&&<>
              <Divider h={1}/>
              <Text b small>ВИМОГИ ДО КАНДИДАТА:</Text>
              <Text style={p_wrap}>{vacancy.requirements}</Text>
            </>}
            
            
            {vacancy.offer&&vacancy.offer.length>1&&<>
              <Divider h={1}/>
              <Text b small>ЩО ПРОПОНУЄ КОМПАНІЯ:</Text>
              <Text style={p_wrap}>{vacancy.offer}</Text>
            </>}
            
            {vacancy.motivation&&vacancy.motivation.length>1&&<>
              <Divider h={1}/>
              <Text b small>ЩО ВАЖЛИВО ПОБАЧИТИ В РЕЗЮМЕ:</Text>
              <Text style={p_wrap}>{vacancy.motivation}</Text>
            </>}
            
         
        
            <Grid xs={24} sm={24} md={24} style={{background:'#eee',padding:'10px',margin:'10px'}} direction="column">
              <Mail />
              {/* <Spacer w={.5}/> */}
              <Description title="Пошта:" content={vacancy.user} />
              <Spacer h={1}/>
              <Description title="Контактна особа:" content={vacancy.name} />
            </Grid> 

          </Grid>
        </>
        :<><Text h3>{error}</Text><Spacer h={24}/></>}
      
      </Grid.Container>

     


    </div>
  );
}

export default Vacancy;
