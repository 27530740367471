import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import Header from './Header';
import { Grid,Themes, Spacer,Text,Breadcrumbs ,CssBaseline,Divider,GeistProvider} from '@geist-ui/core'

const myTheme1 = Themes.createFromLight({
  type: 'coolTheme',
  palette: {
    success: '#1d91c0',
  },
})

let test = {
// border:"1px solid #eee",
// padding:"20px"
}


function Blog1() {
  
  // console.log('Blog1:')


  return (
    <div className="App">
     <Header/>
      <Spacer h={2}/>
      

      <Grid.Container  justify="center" >
        <Grid xs={22} sm={12} md={12}  direction="column">

        <Breadcrumbs>
          <Breadcrumbs.Item href="/">Головна</Breadcrumbs.Item>
          <Breadcrumbs.Item>Блог</Breadcrumbs.Item>
          <Breadcrumbs.Item>Як підготуватися до співбесіди</Breadcrumbs.Item>
        </Breadcrumbs>
        <div style={test}>
        <GeistProvider themes={[myTheme1]} themeType="coolTheme">
        <CssBaseline />
        <Text h1>Як підготуватися до співбесіди</Text>
        
        

<Text p>Співбесіда є найважливішим методом відбору персоналу. Зазвичай її проводять HR-менеджери, рекрутери або керівники підрозділу. Однак співбесіда - це не іспит в університеті, а, перш за все, діалог та можливість себе презентувати.</Text>
<Text p>До кожної співбесіди можна та потрібно готуватися. Це зменшить ступінь хвилювання та допоможе вам краще відповідати на питання.</Text>
<Text p>Ось 10 порад з підготовки до співбесіди для закупівельників:</Text> 

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>1. Вивчіть профіль компанії</Divider>
<Spacer h={1}/>
<Text p> 
Ознайомтеся з її продуктами, послугами, ринками, конкурентами та іншою важливою інформацією, щоб бути готовими до можливих запитань. </Text>
<Text p>Це дозволить вам зрозуміти, як ваші навички та досвід відповідають потребам компанії, а також подготує вас до відповіді на питання про обізнаність щодо діяльності компанії.</Text>
    
<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>2. Проаналізуйте вакансію</Divider>
<Spacer h={1}/>

<Text p>Уважно прочитайте опис обов'язків та вимог до посади, зверніть увагу на ключові навички та кваліфікацію.</Text>
<Text p>Це дозволить вам скласти структурувати всі ваші релевантні навички і якості та допоможе конструктивно і впевнено відповісти на питання, чому саме ви ідеальний кандидат на цю вакансію.</Text>
    
<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>3. Подумайте про свою мотивацію працювати саме в цій компанії саме на цій посаді </Divider>
<Spacer h={1}/>

<Text p>Висловіть свою особисту пристрасть до продукту/послуги/місії роботодавця. Поясніть, чому вам подобаються обов’язки цієї ролі. Опишіть, як ви бачите себе успішним у цій ролі, враховуючи ваші навички та досвід.</Text>
<Text p>Це допоможе вам відповісти на питання "Чому ви хочете тут працювати?" </Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>4. Розберіться з ключовими термінами і поняттями закупівельної сфери
</Divider>
<Spacer h={1}/>

<Text p>Особливо варто звернути увагу на цей пункт, якщо ви переходите з публічних закупівель у бізнес-сектор або навпаки. Якщо у тексті вакансії містяться невідомі вам терміни чи назви інструментів, якими ви не користувалися, знайдіть в інтернеті всю можливу інформацію та за можливості пройдіть ознайомчі відео уроки. </Text>
<Text p>Ознайомлення з ключовими термінами і поняттями у сфері закупівель допоможе вам розуміти запитання при перевірці навичок та кваліфікацій, краще орієнтуватися та відповідати на питання.</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>5. Вивчіть своє резюме
</Divider>
<Spacer h={1}/>
<Text p>Будьте готові логічно та конструктивно, без образ та звинувачень, пояснити причини зміни роботи у минулому і зараз, описати функціонал, успішні кейси та результати на кожній посаді, яку ви обіймали, а також розповісти про причини перерв у кар'єрі (career gaps), якщо такі були, та чим важливим ви в той час займалися (виховання дитини, волонтерство, вивчення іноземних мов, здобуття освіти).</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>6. Підготуйте відповіді на типові питання</Divider>
<Spacer h={1}/>
<Text p>Запитання можуть стосуватися вашого досвіду, навичок, досягнень, викликів, знань про ринок тощо. Згадайте конкретні кейси та ситуації. Це стосується не тільки історій успіху. Вас можуть попросити згадати і найбільші помилки та факапи. Тому краще підготуватися до такого питання і обов'язково зробити висновок - що ви винесли, які висновки зробили та що вдосконалили після тієї ситуації.</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>7. Потренуйтеся структурувати свої відповіді за методом STAR</Divider>
<Spacer h={1}/>
<Text p>S - Situation - Ситуація</Text>
<Text p>T - Target - Мета</Text>
<Text p>A - Action - Дія</Text>
<Text p>R - Result - Результат</Text>
<Text p>Це допоможе вам чітко та логічно давати відповіді на співбесіді.</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>8. Підготуйте запитання для роботодавця</Divider>
<Spacer h={1}/>
<Text p>Завжди уважно розглядайте свої запитання, щоб переконатися, що відповіді нелегко знайти в Інтернеті. </Text>
<Text p>Ці запитання допоможуть вам отримати інформацію, необхідну для прийняття зваженого рішення, а також підтвердить вашу зацікавленість у роботі.</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>9. Оберіть відповідний одяг </Divider>
<Spacer h={1}/>
<Text p>Доглянутий, професійний вигляд важливий. Хороший спосіб визначити відповідний одяг - подивитися на людей, які працюють в організації. Уникайте сильних ароматів та яскравих кольорів.</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>10. Зберігайте спокій</Divider>
<Spacer h={1}/>
<Text p>Попередня підготовка до співбесіди та можливих питань сприятиме вашій впевненості безпосередньо під час розмови. Подолати хвилювання вам допоможе техніка глибокого та рівномірного дихання.</Text>
<Text p>Це допоможе заспокоїтися та зосередитися на розмові. </Text>
</GeistProvider>
</div>

        </Grid>
       
        </Grid.Container>


    </div>
  );
}

export default Blog1;
