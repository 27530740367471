import React from 'react';
import Header from './Header';
import axios from 'axios';
import { Grid,Card, Spacer,Text,Description,Loading,Tooltip,Select,Button,Pagination,
  CssBaseline, GeistProvider, Themes,Divider,useMediaQuery} from '@geist-ui/core'
import { NavLink } from 'react-router-dom';
// import { ArrowRightCircle } from '@geist-ui/icons'
import Award from '@geist-ui/icons/award'
import MapPin from '@geist-ui/icons/mapPin'
import { useForm, Controller } from "react-hook-form";

const myTheme1 = Themes.createFromLight({
  type: 'coolTheme',
  palette: {
    success: '#1d91c0',
  },
})

let selection = {
  padding:'10px',
  borderRight:'1px solid #eee'
}
let selection2 = {
  padding:'0px 5px 0px 0px',
}

let test = {
  // border:'1px solid red'
  }

function Allresume() {

  const [resume, setResume] = React.useState([]);
  const [page, setPage] = React.useState([1]);
  const [error, setError] = React.useState(false);

  const { control, handleSubmit,getValues } = useForm({
    defaultValues: {
      type: 'РЕЗЮМЕ',
      role:"Будь яка",
      position:"Будь який",
      sector:"Будь який",
      region:"Вся Україна",

    }
  });
  const onSubmit = d => {
    console.log('!',d);
    getTotalResume(page,d.type,d.role,d.position,d.sector,d.region)
  };

  React.useEffect(() => {
    getTotalResume(page,getValues().type,getValues().role,getValues().position,getValues().sector,getValues().region)
  }, [page]);


  const getTotalResume = (page,type,role,position,sector,region) => {
    axios.get(`https://cepjobs.vercel.app/api/allresume?page=${page}&type=${type}&role=${role}&position=${position}&sector=${sector}&region=${region}`)
    .then(response => {
      // console.log('allresume ',response)
      response.data.length==0?setError(true):setError(false)
      setResume(response.data);
    }).catch(error => {
    });
  }

  const isXS = useMediaQuery('xs')


  console.log('Allresume:',resume)


  return (
    <>
      <Header/>
      
      <div style={{ background:'#3fd4ad'}}>
      
      <Spacer h={4.2}/>
      
      <Grid.Container justify="center" style={selection}>
        
        {/* <Grid xs={23} sm={23} md={2} style={selection}>
          <Text small type="secondary" style={selection2}>Фільтри відборів</Text>
        </Grid> */}

        <Grid xs={23} sm={12} md={4} style={selection} direction="column">
          <Text small style={selection2}>Роль:</Text>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
              <Select style={{border:'1px solid #555'}} {...field} placeholder="Будь яка" >
                  <Select.Option value="Будь яка">Будь яка</Select.Option>
                  <Select.Option divider />
                  {
                  roles.map((d,index)=>(
                    <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                  ))
                }   
              </Select>
            }
          />
        </Grid>
        

        
        <Grid xs={23} sm={12} md={4} style={selection} direction="column">
          <Text small style={selection2}>Рівень:</Text>
          <Controller
                name="position"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => 
                <Select style={{border:'1px solid #555'}} {...field}  placeholder="Будь який" >
                  <Select.Option value="Будь який">Будь який</Select.Option>
                  <Select.Option divider />
                  <Select.Option value="Початковий рівень">Початковий рівень</Select.Option>
                  <Select.Option value="Просунутий рівень">Просунутий рівень</Select.Option>
                  <Select.Option value="Керівний рівень">Керівний рівень</Select.Option>
                </Select>
                }
          />
        </Grid>

        <Grid xs={23} sm={12} md={4} style={selection} direction="column">
          <Text small style={selection2}>Сектор:</Text>
          <Controller
                name="sector"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => 
                <Select style={{border:'1px solid #555'}} {...field} placeholder="Будь який" >
                  <Select.Option value="Будь який">Будь який</Select.Option>
                  <Select.Option divider />
                  <Select.Option value="Публічний">Публічний</Select.Option>
                  <Select.Option value="Комерційний">Комерційний</Select.Option>
                  <Select.Option value="Некомерційний">Некомерційний</Select.Option>
                </Select>
                }
          />
        </Grid>

        <Grid xs={23} sm={12} md={4} style={selection} direction="column">
          <Text small style={selection2}>Регіон:</Text>
          <Controller
              name="region"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => 
              <Select style={{border:'1px solid #555'}} {...field}  placeholder="Вся Україна">
                {
                  region.map((d,index)=>(
                    <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                  ))
                }            
              </Select>
              }
            />
        </Grid>

        <Grid xs={23} sm={12} md={3} style={{padding:'5px'}}>
          <Button style={{marginTop:'25px'}} scale={0.75} auto type="secondary" onClick={handleSubmit(onSubmit)}>Відібрати</Button>
        </Grid>
        
      </Grid.Container>
      
    </div>


      
      <Grid.Container  justify="center" style={{background:'#ece7f2'}}>
        <Text type="secondary">Резюме</Text>
      </Grid.Container>

      
      
      
      
      <Grid.Container justify="center" style={{background:'#ece7f2',paddingBottom:'30px',fontFamily: "Roboto"}}>
        {error?<>Нічого не знайдено<Spacer h={24}/></>:resume.length>0?
          resume.map((d,index)=>(
                  
            <Grid key={index} index={index} xs={24} sm={12} md={7} style={test}>
              
              <Card shadow width="100%" style={{margin:'10px'}}>
              <Text type="secondary" style={{fontSize:'10px'}}>{d.type}</Text>
              <NavLink to={`/resume?id=${d.documentId}`}>
              <Text h5 style={{color:'#1d91c0',textDecoration: "underline #1d91c0",height:isXS?'110px':'90px'}}>
                  {d.role?
                    d.role.slice(0,3).map((d1,index1)=>(
                      <div key={index1} index={index1}>{d1}</div>
                    )):d.position}
                </Text>                
              </NavLink>
              
              <Divider />
              
              <Grid.Container style={test}>
                <Grid xs={12} sm={12} md={12} style={test}>
                  <Description title="Сектор:" content= {d.sector} />
                </Grid>
                <Grid xs={12} sm={12} md={12} style={test}>
                  {
                  d.cep&&d.cep.length>0?
                  <>
                    <Description title="Сертифікати CEP:" 
                    content= 
                      {d.cep.map((d2,index2)=>(
                      <Tooltip leaveDelay="0" key={index2} index={index2} text={d2} type="dark">
                        <Award color="#3fd4ad"/>
                      </Tooltip>
                    ))}
                     />
                  </>:<Spacer h={2.8}/>
                  }
                </Grid>
              </Grid.Container>

               
               <Grid.Container style={test}>
                <Grid xs={12} sm={12} md={12} style={test}>
                  <Description title="Створено:" content= {d.dateCreate.replace(/T.+/,'')} />
                </Grid>
                <Grid xs={12} sm={12} md={12} style={test}>
                  <Description title="Регіон:" content={d.region+", "+d.city} />
                </Grid>
              </Grid.Container>
              
            </Card>
          </Grid>
            
           
            
        
            ))
            :[1,2,3,4,5,6,7,8,9].map((d,index)=>(
              <Grid key={index} index={index} xs={22} sm={11} md={7} justify="center">
                <Card width="100%" height="250px"  style={{margin:'10px'}}>
                  <Loading />
                </Card>
              </Grid>
               ))
            }
             
          <Spacer h={3}/>
      </Grid.Container>

      

      <Spacer h={2}/>

      <Grid.Container  justify="center">
      <Spacer h={2}/>
      <GeistProvider themes={[myTheme1]} themeType="coolTheme">
        <CssBaseline />
        <Pagination count={5} initialPage={1} onChange={(val) => {
          // window.history.pushState("object or string", "Page Title", `?page=${page}&position=${getValues().position}`);
          setPage(val)
        }}/>
      </GeistProvider>

      </Grid.Container>
      <Spacer h={2}/>
      
    </>
  );
}

let region = [
  "Вся Україна",
  "Вінницька",
  "Волинська",
  "Дніпропетровська",
  "Донецька",
  "Житомирська",
  "Закарпатська",
  "Запорізька",
  "Івано-Франківська",
  "Київська",
  "Кіровоградська",
  "Луганська",
  "Львівська",
  "Миколаївська",
  "Одеська",
  "Полтавська",
  "Рівненська",
  "Сумська",
  "Тернопільська",
  "Харківська",
  "Херсонська",
  "Хмельницька",
  "Черкаська",
  "Чернівецька",
  "Чернігівська"
]

let roles = [
  "Менеджер із закупівель",
  "Фахівець з публічних закупівель",
  "Уповноважена особа",
  "Експерт із закупівель",
  "Спеціаліст з тендерних закупівель",
  "Категорійний менеджер",
  "Фахівець / фахівчиня із закупівель",
  "Керівник логістичного центру",
  "Керівник категорійного відділу",
  "Провідний фахівець із закупівель",
  "Фахівець з договірної роботи",
  "Менеджери ЗЕД",
  "Керівник відділу закупівель та постачання",
  "Менеджери з закупівель та постачання, проєктні менеджери",
  "Директори із закупівель, керівники відділів"
]

export default Allresume;
