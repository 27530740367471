import React, { useState } from 'react';
import axios from 'axios';
// import { setUserSession } from './Utils/Common';
import { Divider,Grid,Input,Button,Spacer,Fieldset,Text,Link } from '@geist-ui/core'
// import Googlelog from './Googlelog';
// import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLoginButton } from "react-social-login-buttons";
import {useHistory } from 'react-router-dom';
import {setUserSession } from './Utils/Common';
import Header from './Header';


function Register() {

  const history = useHistory();

  // const [state, setState] = useState(false)
  // const handler = () => setState(true)
  // const closeHandler = (event) => {
  //   setState(false)
  //   console.log('closed')
  // }
  
    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
          axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: 'application/json'
                  }
                })
              .then((res) => {
                // console.log('google log!',res.data.email);
                axios.post('https://cepjobs.vercel.app/api/googlelogin',{user:res.data.email})
                .then(response => {
                  // setLoading(false);
                  // console.log('response',response)
                  setUserSession(response.data.token,response.data.user);
                  history.push('/dashboard');
                  setError('Дякуємо!')
                }).catch(error => {
                  // setLoading(false);
                  setError('Помилка')
                });
              })
              .catch((err) => console.log('err'));

        },
        onError: (error) => console.log('Login Failed:', 'error')
    });
    


    // React.useEffect(() => {
    //   const params = new URLSearchParams(window.location.search)
    //   let token = params.get('token')
    //   let user = params.get('user')
    //   setUserSession(token,user);
    //   console.log('Verify_ ',token,user)
    //   const timeout = setTimeout(() => {
    //     history.push('/dashboard');
    //    }, 100);
    //    return () => clearTimeout(timeout);
    // }, [])


//   const logOut = () => {
//     googleLogout();
//     setProfile(null);
// };


  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const [error, setError] = useState('');
  

  const handleLogin = () => {
    
    if(username.value==""){
      setError('Введіть пошту')
      return
    }
    if(username.value.includes('.ru')){
      setError('Русні не можна')
      return
    }
    if(!username.value.includes('@')){
      setError('Невалідна адреса')
      return
    }
    // setError(null);
    setLoading(true);
    axios.post('https://cepjobs.vercel.app/api/register',{user:username.value})
    .then(response => {
      setLoading(false);
      // console.log('response',response)
      setError('Будь ласка, завершіть вхід через пошту')
    }).catch(error => {
      setLoading(false);
      setError('Помилка')
    });
  }



  // console.log("🚀 ~")
  // a.gribanovskiy@gmail.com

  return (
    <>
     <Header/>
        <Spacer y={1}/>

        <Grid.Container justify="center">
        <Grid xs={22} sm={12} md={6} >
        
        <Fieldset style={{marginTop:'50px'}}>
        <Fieldset.Title>Увійти за допомогою пошти</Fieldset.Title>
        <Fieldset.Subtitle>
          Введіть власну пошту
        <Input width='95%' status="secondary" placeholder="email" {...username} />
        <Spacer y={1}/>
        <Button style={{width:'90%'}} type="secondary" onClick={handleLogin} disabled={loading} >{loading ? 'Loading...' : 'Увійти'}</Button>
        <Spacer y={1}/>
        <Text type="warning">{error}</Text>  

        <Spacer y={1}/>
        <Divider width="90%">або</Divider>
        <Spacer y={1}/>

        <GoogleLoginButton style={{width:"87%",fontSize:'14px'}} onClick={() => googleLogin()}/>

        <Spacer y={1}/>
        </Fieldset.Subtitle>

            

        <Fieldset.Footer>
        <small>Реєструючись, ви приймаєте <a href="/ugoda" >умови надання послуг</a></small>
          </Fieldset.Footer>
        </Fieldset>

         </Grid> 
        </Grid.Container>
        <Spacer y={1}/>
        
        {/* <div>
      
      <Modal visible={state} onClose={closeHandler}>
        <Modal.Title>Modal</Modal.Title>
        <Modal.Subtitle>This is a modal</Modal.Subtitle>
        <Modal.Content>
          <p>Some content contained within the modal.</p>
        </Modal.Content>
        <Modal.Action passive onClick={() => setState(false)}>Cancel</Modal.Action>
        <Modal.Action>Submit</Modal.Action>
      </Modal>
    </div> */}
    
    </>
  );
}


const useFormInput = initialValue => {
  
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Register;