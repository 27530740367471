import React from 'react';
import axios from 'axios';
import { Grid,Card, Image,Description,Spacer,Text,Divider,Loading,Link
,Tooltip } from '@geist-ui/core'
import { NavLink } from 'react-router-dom';
import { ArrowRightCircle } from '@geist-ui/icons'
import Award from '@geist-ui/icons/award'
import MapPin from '@geist-ui/icons/mapPin'
// import { HashLink } from 'react-router-hash-link';
// import { QuestionCircle } from '@geist-ui/icons'
// import cover from './img/cover.jpg';
import Header from './Header';

import point1 from './img/point1.png';
import point2 from './img/point2.png';
import point3 from './img/point3.png';

import im1 from './img/im1.jpg';
import im2 from './img/im2.jpg';
import im3 from './img/im3.jpg';



let test = {
  // margin:'5px',
  // background:'#ddd',
  border:'1px solid red'
}
function Home() {

  // const [resume, setResume] = React.useState([]);
  const [vacancy, setVacancy] = React.useState([]);
  const [stats, setStats] = React.useState(null);
  // const [state, setState] = React.useState(false)
  // const handler = () => setState(true)
  
  // React.useEffect(() => {
  //   axios.get(`http://localhost:5000/api/statistic`)
  //   .then(response => {
  //     setStats(response.data);
  //   }).catch(error => {
  //   });
  // }, []);
  
  
  // React.useEffect(() => {
  //   axios.get(`http://localhost:5000/api/resume3`)
  //   .then(response => {
  //     setResume(response.data);
  //   }).catch(error => {
  //   });
  // }, []);


  React.useEffect(() => {
    axios.get(`https://cepjobs.vercel.app/api/vacancy3`)
    .then(response => {
      setVacancy(response.data);
      // console.log('response.data',response.data)
    }).catch(error => {
    });
  }, []);
  
  // console.log('Home:',stats)


  return (
    <>
      <Header/>
      
      <Grid.Container  justify="center"  style={{'background':'#3fd4ad',color:'#fff'}} >
        <Grid xs={22} sm={18} md={12} justify="center" direction="column">
          <Text style={{fontSize:'200%'}} h1>Робота в закупівлях</Text>
          <Text style={{fontSize:'120%'}}>CEP Jobs  — це українська платформа, що з’єднує професіоналів із закупівель із компаніями, які шукають закупівельників</Text>
          </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>

     

      {/* <Modal visible={state}>
        <Modal.Subtitle>This is a modal</Modal.Subtitle>
        <Modal.Content>
        Системно розвиваємо професію закупівельника у публічному секторі (долучилися до створення професійного стандарту “Фахівець з публічних закупівель та обов’язкового Тестування уповноважених осіб) та комерційному секторі (провели у партнерстві з компаніями «Deloitte» в Україні та Odgers Berndtson Опитування Лідерів Закупівель України. Це перше в Україні опитування керівників закупівельної ланки лише комерційного сектору.)        </Modal.Content>
       
      </Modal> */}

      <Grid.Container  justify="center">
        <Grid xs={22} sm={7} md={7} alignItems="center" direction="column" style={{margin:'20px'}}>
            <Image width="150px" src={point1} />
            <Text h3>Поширення серед закупівельників</Text>
            <Text>CEP нараховує 1300+ випускників і спільноту закупівельників, що налічує понад 8 000 професіоналів. Додайте вашу вакансію й її побачить зацікавлена аудиторія</Text>
        </Grid>
        <Grid xs={22} sm={7} md={7} alignItems="center" direction="column" style={{margin:'20px'}}>
            <Image width="150px" src={point2} />
            <Text h3>Просування вакансій</Text>
            <Text>Наша команда розмістить інформацію про вашу вакансію на всіх своїх ресурсах</Text>
        </Grid>
        <Grid xs={22} sm={7} md={7} alignItems="center" direction="column" style={{margin:'20px'}}>
            <Image width="150px" src={point3} />
            <Text h3>Безкоштовна платформа</Text>
            <Text>З 2016 року ми системно розвиваємо професію закупівельника. Ця ініціатива - є запорукою стійкого розвитку закупівельного ринку України</Text>
        </Grid>
        
      </Grid.Container>


{/* 
      <Spacer h={1}/>
      <Divider />
      <Spacer h={1}/>
      
      <Grid.Container justify="center" 
      // style={{fontFamily: "Roboto"}}  
      style={test}>
      
      <Grid xs={24} sm={12} md={7} direction='column' alignItems="center" style={test}>
        {stats?<>
          <Text small type="secondary">Активних резюме:</Text>
          <Text style={{fontSize:"500%",fontFamily: "Roboto"}}>{stats[0].counts}</Text>
        </>:''}
      </Grid>
      <Grid xs={24} sm={12} md={7} direction='column' alignItems="center" style={test}>
       {stats?<>
          <Text small type="secondary">Вакансій:</Text>
          <Text h1>{stats[1].counts}</Text>
        </>:''}
      </Grid>
    
      </Grid.Container>
      
      <Spacer h={1}/> */}


      <Grid.Container  justify="center" style={{background:'#ece7f2',fontFamily: "Roboto"}}>
        <Text type="secondary">Вакансії</Text>
      </Grid.Container>
      
      <Grid.Container justify="center" style={{background:'#ece7f2',fontFamily: "Roboto"}}>
        {vacancy.length>0?
          vacancy.map((d,index)=>(
            <Grid key={index} index={index} xs={24} sm={12} md={7}>
              <Card shadow width="95%" style={{margin:'10px',paddingRight:'25px', 
              background: "#fff"}}>
                
                <div>
                  {d.logo!=''?d.logo.includes('http')&&(d.logo.includes('png')||d.logo.includes('jpg')||d.logo.includes('jpeg')||d.logo.includes('svg'))&&<Image style={{paddingTop:'10px'}} width='40%' src={d.logo} className="images"/>:d.image?.data?<Image style={{paddingTop:'0px',width: "350px",height: "60px"}} src={"data:image/jpeg;base64, "+d.image?.data}/>:''}
                </div>
                
       
                <Text type="secondary" style={{fontSize:'10px'}}>{d.type}</Text>
                
                <NavLink to={`/vacancy?id=${d.documentId}`}>
                  <Text h3 style={{color:'#1d91c0',textDecoration: "underline #1d91c0",minHeight:"80px"}} my={0}>
                  {d.position}
                  </Text>                
                </NavLink>
                
                <Divider />
                
                <Grid.Container style={{paddingTop:'15px'}}>
                  <Grid xs={12} sm={12} md={14}>
                    <Description title="Компанія:" content= {d.org} />
                  </Grid>
                  <Grid xs={12} sm={12} md={1}>
                    </Grid>
                  <Grid xs={12} sm={12} md={9}>
                    {/* <Description title="Сектор:" content= {d.sector} /> */}
                    <Description title="Місце:" content={d.region+", "+d.city} />
                  </Grid>
                </Grid.Container>
{/* 
                <Grid.Container style={{paddingTop:'15px'}}>
                  <Grid xs={12} sm={12} md={14}>
                    <Description title="Дійсна до:" content= {d.deadline} />
                  </Grid>
                  <Grid xs={12} sm={12} md={10}>
                    <Description title="Місце:" content={d.region+", "+d.city} />
                  </Grid>
                </Grid.Container> */}
                
              </Card>
            </Grid>
        
            ))
            :[1,2,3].map((d,index)=>(
              <Grid key={index} index={index} xs={24} sm={12} md={7} justify="center">
                <Card width="95%" height="350px"  style={{margin:'10px'}}>
                  <Loading />
                </Card>
              </Grid>
               ))
            }
          
      </Grid.Container>
      
      <Grid.Container  justify="center" style={{background:'#ece7f2'}}>
        <Grid xs={20} sm={20} md={20} justify="flex-end">
        <NavLink to="/allvacancy" >
            <Text style={{color:'#000',textDecoration: "underline #3fd4ad"}}>Більше у розділі <ArrowRightCircle />  </Text>      
          </NavLink>
        </Grid>
      </Grid.Container>

      
      
      
      
      
      
      
      
      
      
      <Grid.Container  justify="center">
        <Text>Корисні статті і поради</Text>
      </Grid.Container>
      
      <Grid.Container  justify="center">
        <Grid xs={22} sm={11} md={7}>
          <Link target="_blank" href="/jak-pіdgotuvatisja-do-spіvbesіdi">
            <Card shadow width="95%" style={{margin:'10px'}}>
              <Image src={im1} draggable={true} />
              <Text h3 mb={1}>Як підготуватися до співбесіди</Text>
            </Card> 
          </Link>
        </Grid>
        <Grid xs={22} sm={11} md={7}>
          <Link target="_blank" href="/jakі-pitannja-staviti-zakupіvelniku">
          <Card shadow width="95%" style={{margin:'10px'}}>
            <Image src={im2} draggable={true} />
              <Text h3 mb={1}>Які питання ставити закупівельнику</Text>
            </Card> 
          </Link>
        </Grid>
        <Grid xs={22} sm={11} md={7}>
          <Link target="_blank" href="/jak-napisati-suprovіdnij-list">
            <Card shadow width="95%" style={{margin:'10px'}}>
              <Image src={im3} draggable={true} />
              <Text h3 mb={1}>Як написати супровідний лист</Text>
            </Card> 
          </Link>
        </Grid>
      </Grid.Container> 
      
      
    </>
  );
}

export default Home;
