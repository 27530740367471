import React from 'react';
import { Grid,Button,Image, Avatar, Link, useMediaQuery, Popover,Text } from '@geist-ui/core'
import { getUser, removeUserSession } from './Utils/Common';
// import MoreVertical from '@geist-ui/react-icons/moreVertical'
import { NavLink,useHistory } from 'react-router-dom';
import { Menu } from '@geist-ui/icons'
import ChevronDown from '@geist-ui/icons/chevronDown'


import logo from './img/logo_green.png';



let test = {
  color:'#fff'
  // 'border':'1px solid red'
}

export default function Header() {
  
  
  const history = useHistory();
  const user = getUser();
  const isXS = useMediaQuery('xs')
  
  const handleLogout = () => {
    removeUserSession();
    history.push(`/register`);
  }
  
  
  const more = () => (
    <>
      <Popover.Item>
      <Link href="/about" >Інструкції</Link>
      </Popover.Item>
      <Popover.Item>
      <Link  href="https://cep.kse.ua/" >Про нас</Link>
      </Popover.Item>
      <Popover.Item>
      <Link  href="https://cep.kse.ua/programs.html" >Наше навчання</Link>
      </Popover.Item>
      <Popover.Item>
      <Link  href="https://cep.kse.ua/publication.html" >Аналітика</Link>
      </Popover.Item>
      <Popover.Item>
      <Link  href="https://cep.kse.ua/tools.html" >Інструменти</Link> 
      </Popover.Item>
    </>
  )

  const cabinet = () => (
    <>
      <Popover.Item title>
        <span>{user}</span>
      </Popover.Item>
      <Popover.Item>
          <NavLink activeClassName="active" to="/dashboard" >
            <Button>Мій кабінет</Button> 
          </NavLink>
      </Popover.Item>
      {/* <Popover.Item>
        <NavLink activeClassName="active" to="/about" >
          Інструкції
        </NavLink>
      </Popover.Item>     */}
      <Popover.Item>
        <Link onClick={handleLogout} href="#">
        {/* Вийти */}
          <Button type="abort">Вийти</Button> 
        </Link>
      </Popover.Item>
    </>
  )

  const mobile = () => (
    <>
      <Popover.Item>
        <NavLink to="/" >
          <Text span type="secondary">Головна</Text>
        </NavLink>
      </Popover.Item>
      <Popover.Item>
        <NavLink to="/allresume" >
          <Text span type="secondary">Резюме</Text>
        </NavLink>
      </Popover.Item>
      <Popover.Item>
        <NavLink to="/allvacancy">
          <Text span type="secondary">Вакансії</Text>
        </NavLink>
      </Popover.Item>
      <Popover.Item>
        <Link href="/about" >Інструкції</Link>
      </Popover.Item>
      <Popover.Item>
        <Link  href="https://cep.kse.ua/" >Про нас</Link>
      </Popover.Item>
      <Popover.Item>
        <Link  href="https://cep.kse.ua/programs.html" >Наше навчання</Link>
      </Popover.Item>
      <Popover.Item>
        <Link  href="https://cep.kse.ua/publication.html" >Аналітика</Link>
      </Popover.Item>
      <Popover.Item>
        <Link  href="https://cep.kse.ua/tools.html" >Інструменти</Link> 
      </Popover.Item>
      
    </>
  )

// console.log('HEADER:',isXS)

  return (

    // <Image width="100%" height="260px" src={cover} />

    <Grid.Container justify="center" direction="row" alignItems="center"
      style={{'background':'#3fd4ad',padding:"10px"}}
      >
      
        {user&&user!='null'?
        <>
          <Grid xs={0} sm={0} md={2} style={test}>
          </Grid>
          <Grid xs={8} sm={4} md={3} style={test}>
                <NavLink  to="/" >
                  <Image width={isXS?"50px":"50px"} src={logo} />
                </NavLink>
          </Grid>
          
          <Grid xs={10} sm={0} md={4} style={test}>
           
          </Grid>
          <Grid xs={0} sm={4} md={2} style={test}>
              <NavLink  to="/" >
                  <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase'}} auto 
                  type="abort">Головна</Button> 
              </NavLink>
          </Grid>
          
         
          
         
          <Grid xs={0} sm={4} md={2}>
              <NavLink to="/allvacancy">
                <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase'}} auto 
                    type="abort">ВАКАНСІЇ
                </Button> 
              </NavLink>
          </Grid>
          
          <Grid xs={0} sm={4} md={2} style={test}>
              <NavLink  to="/allresume" >
                  <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase'}} auto 
                  type="abort">Резюме</Button> 
              </NavLink>
          </Grid>
          
          <Grid xs={0} sm={4} md={3}>
            <Popover content={more} placement="bottomEnd" hideArrow='true'>
              <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase',background:'#E1555A'}} icon={<ChevronDown color='#fff'/>} auto>Більше</Button> 
            </Popover>
          </Grid>
        
          <Grid xs={3} sm={4} md={3}>
            <Popover content={cabinet} placement="bottomEnd">
              <Link href="#"><Avatar scale={2} text={user.slice(0,3)} /></Link>
            </Popover>
          </Grid>
          
          <Grid xs={2} sm={0} md={0}>
            <Popover content={mobile} placement="bottomEnd">
              <Menu />
            </Popover>
          </Grid>
          
          
        
          
        </>:
        
        
        <>
          <Grid xs={0} sm={0} md={2} style={test}>
          </Grid>
          <Grid xs={8} sm={4} md={3} style={test}>
                <NavLink  to="/" >
                  <Image width={isXS?"50px":"50px"} src={logo} />
                </NavLink>
          </Grid>
          <Grid xs={7} sm={0} md={4} style={test}>
           
          </Grid>
          <Grid xs={0} sm={4} md={2} style={test}>
                <NavLink activeClassName="active" to="/" >
                  <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase'}} auto 
                  type="abort">ГОЛОВНА
                  {/* <Text style={{ color: '#fff' }}>ГОЛОВНА</Text> */}
                  </Button> 
                  
                </NavLink>
          </Grid>
          
          <Grid xs={0} sm={4} md={2}>
            <NavLink  to="/allvacancy">
                <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase'}} auto 
                    type="abort">ВАКАНСІЇ
                </Button> 
            </NavLink>
          </Grid>
          
          <Grid xs={0} sm={4} md={2}>
            <NavLink activeClassName="active" to="/allresume" >
                <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase'}} auto 
                    type="abort">Резюме
                </Button> 
              </NavLink>
          </Grid>
         
          
          <Grid xs={0} sm={4} md={3}>
            <Popover content={more} placement="bottomEnd" hideArrow='true'>
              <Button style={{color:'#fff',fontFamily:"'Roboto', sans-serif",textTransform: 'uppercase',background:'#E1555A'}} icon={<ChevronDown color='#fff'/>} auto>Більше</Button> 
            </Popover>
          </Grid>
         

          <Grid xs={6} sm={4} md={3} style={test}>
              <NavLink activeClassName="active" to="/register" >
                <Button style={{textTransform: 'uppercase',fontFamily:"'Roboto', sans-serif"}} type="secondary" auto >Увійти</Button>
              </NavLink>
          </Grid>
          
          <Grid xs={2} sm={0} md={0}>
            <Popover content={mobile} placement="bottomEnd">
              <Menu />
            </Popover>
          </Grid>
          
        </>
            

          
              }    
          
        
      </Grid.Container>

  );

}
