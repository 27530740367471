import React from 'react';
// import Header from './Header';
import { setUserSession } from './Utils/Common';
import {useHistory } from 'react-router-dom';

function Savetoken() {

  const history = useHistory();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    let user = params.get('user')
    setUserSession(token,user);
    // console.log('Verify_ ',token,user)
    const timeout = setTimeout(() => {
      history.push('/dashboard');
     }, 200);
     return () => clearTimeout(timeout);
  }, [])

  // console.log('Savetoken: ')

  return (
    <>
      {/* <Header/> */}
      <div>Зачекайте...</div>
    </>
  );
}

export default Savetoken;
