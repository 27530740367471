import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import Header from './Header';
import { Grid,Themes, Spacer,Text,Breadcrumbs ,CssBaseline,Note,GeistProvider} from '@geist-ui/core'

const myTheme1 = Themes.createFromLight({
  type: 'coolTheme',
  palette: {
    success: '#1d91c0',
  },
})

let test = {
// border:"1px solid #eee",
// padding:"20px"
}


function Blog2() {
  
  // console.log('Blog1:')


  return (
    <div className="App">
     <Header/>
      <Spacer h={2}/>
      

      <Grid.Container  justify="center" >
        <Grid xs={22} sm={12} md={12}  direction="column">

        <Breadcrumbs>
          <Breadcrumbs.Item href="/">Головна</Breadcrumbs.Item>
          <Breadcrumbs.Item>Блог</Breadcrumbs.Item>
          <Breadcrumbs.Item>Як написати супровідний лист</Breadcrumbs.Item>
        </Breadcrumbs>
        <div style={test}>
        <GeistProvider themes={[myTheme1]} themeType="coolTheme">
        <CssBaseline />
        <Text h1>Як написати супровідний лист</Text>
        
        

<Text p>Супровідний лист,  як видно з назви, супроводжує Ваше резюме/ СV, відправлене на розгляд роботодавцю. В бізнес-комунікації існує стандартне уявлення про вміст супровідного листа та його функцію, але варто зазаначити, що сьогодні з'являється багато різних думок щодо необхідності взагалі супроводжувати ним відправку резюме/ CV. Ми рекомендуємо в кожному конкретному випадку окремо приймати рішення про необхідність додавати до Вашого резюме/ СV супровідний лист і про його вміст, оцінивши контекст, умови, середовище і стейкхолдерів вакансії. </Text>
<Text p>"Класичний" підхід до супровідного листа і його ролі в отриманні роботи: це обов'язкова частина пакету подання на вакансію, до якої ще входить резюме/ CV та (можливо) мотивацийний лист, скани дипломів, рекомендації та інші документи, які може запросити потенційний роботодавець.  У разі відгуку на вакансію за ел. поштою супровідний лист має бути написаний, наприклад, в тілі електронного листа, до якого вже прикріплюється резюме/ CV, та інші документи. Не варто плутати супровідний і мотиваційний листи: супровідний лист лише "супроводжує" пакет документів на вакансію, в той час як мотиваційний - надає аргументацію, чому саме Вас потрібно обрати на вакансію. </Text> 

{/* <Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>1. Вивчіть профіль компанії</Divider>
<Spacer h={1}/> */}

<Text h3>Супровідний лист має містити таку інформацію (рекомендовано):</Text>

<Text p> • Ваше представлення і джерело, звідки Ви дізнались про вакансію (згадка про сайт, пост в соціальних мережах, конкретне оголошення, професійна спільнота закупівельників, конкурс, тощо). Якщо Ви дізнались від конкретної людини, яка надала вакансію і контакт, - то гарним тоном буде вказати цей факт й ім'я людини, якщо це доречно;</Text>

<Text p>• Ваша аргументація, чому Ви хочете працювати саме в цій компанії, в чому для Вас полягає цінність долучитись до команди, чому саме Вас мають обрати на цю вакансію - цю тему варто розкривати в супровідному листі дуже коротко, особливо, якщо в пакет ще буде входити мотиваційний лист;</Text>

<Text p>• Посилання на Ваше резюме/ CV  і коментарі до нього: можливі пояснення до інформації, яка не вписується в формат або може викликати питання роботодавця, наприклад, “прогалини” в стажі, невідповідність Вашого досвіду вакансії, на яку ви подаєтесь, або інші слабкі/ дискусійні місця. Тут варто вміти критично подивитись на своє готове резюме/ CV, передбачити, де у потенційного роботодавця можуть з'явитися питання або сумніви, і наперед прокоментувати їх в цій частині супровідного листа;</Text>

<Text p> • Ваша готовність і мотивація  до наступних етапів рекрутингового процесу - співбесіди, конкурсу, тест-завдання тощо;</Text>

<Text p> • Ваші контакти: ел. адресу, телефон, мессенджери. </Text>

<Text h3>Окрім загальних рекомендацій, у випадку супровідного листа на вакансію саме закупівельника/ фахівця з постачання пропонуємо наступні поради: </Text>

<Text p>1. Коротко покажіть свою особисту історію розвитку в професії, Ваші попередні ролі і позиції,  приналежність і лояльність професійній спільноті, певним мережам або галузям, в яких Ви працюєте.</Text>
<Text p>2. Використовуйте професійну мову, підкресліть володіння важливими для професії навичками і знаннями: вміння робити аналіз попередніх закупівель, знання ринків і ланцюгів постачання, розуміння закупівельних процесів, вміння управляти відносинами з постачальниками й ініціаторами закупівель тощо.</Text>
<Text p>3. Поділіться своїми професійними успіхами й досягненнями: вдалими контрактами/тендерами, впровадженням цифрових рішень,  налагодженими бізнес-процесами тощо, рівнем досягнутої економії тощо.</Text>
<Text p> 4. Розкажіть, з якими закупівельними категоріями Ви працювали, чи хочете диверсифікувати ваше “портфоліо” категорій і чому.</Text>
<Text p> 5. Поділіться, які лідери або експерти закупівель країни або світу мотивують і надихають Вас, вкажіть, які джерела професійної інформації Ви використовуєте в роботі.</Text>

<Text p>Головні принципи в написанні супровідного листа - лаконічність, щирість, легкість, позитив і демонстрація Вашої відкритості і мотивації до співпраці з роботодавцем в процесі рекрутингу. На відміну від резюме/ CV, яке має дуже чіткий формат, в супровідному листі можна побачити трохи більше про Вас як особистість (до співбесіди) - чи грамотно Ви пишете, які слова добираєте, як будуєте речення тощо. Якщо в пакеті подання на вакансію немає мотиваційного листа - то супровідний лист частково візьме на себе цю функцію, тож є вірогідність, що його прочитають більш уважно. </Text>

<Text p>В принипі, варто розуміти, що потенційний роботодавець, особливо якщо він “завалений” пропозиціями кандидатів, може дійсно дуже мало уваги приділити Вашому супровідному листу, але повірте, що кілька якісних речень особисто від Вас психологічно налаштують компанію/ рекрутерів на позитивне сприйняття й аналіз Вашої кандидатури!</Text>

<Text p>Нижче надаємо підказку до написання супровідного листа, яку можна використати за основу: </Text>

<Note label={false}>

<Text p>Шановний [Ім’я, прізвище менеджера з найму, HR - менеджера] </Text>
<Text p>Цим листом я би хотів/ла відгукнутись на вакансію [.........], опубліковану [........]</Text>

<Text p>[Представтеся, пояснюючи, чому ви подаєте заявку на роботу, як ця робота відповідає вашим кар’єрним цілям і що саме приваблює вас у компанії. Поясніть свій відповідний досвід і кваліфікацію узагальнено, не повторюючи те, що міститься у вашому доданому резюме/ CV. Виділіть одне-два відповідних досягнення з фактами та даними, якщо це можливо.Поясніть, чому ви підходите для компанії].</Text>

<Text p>[Підтвердіть свою зацікавленість в позиції. Висловіть готовність до співбесіди і бажаний спосіб зв’язку.]</Text>

<Text p>[Надайте Ваші контакти]</Text>

<Text p>[Підпис]</Text>

<Text p>Бажаємо успіху в пошуку роботи!</Text>
</Note>


</GeistProvider>
</div>

        </Grid>
       
        </Grid.Container>


    </div>
  );
}

export default Blog2;
