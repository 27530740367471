import React from 'react';
import { getUser,getToken } from './Utils/Common';
import { Grid,Description, Checkbox,useMediaQuery,Dot,Loading,Text,Spacer,Fieldset,Table,Popover,Button,Divider,Toggle } from '@geist-ui/core'
import axios from 'axios';
import Header from './Header';

// import uniqid from 'uniqid';
// import queryString from 'query-string';
import ResumeAdd from './ResumeAdd';
import VacancyAdd from './VacancyAdd';

import ArrowDown from '@geist-ui/icons/arrowDown'
import ExternalLink from '@geist-ui/icons/externalLink'
import Trash2 from '@geist-ui/icons/trash2'
import { NavLink } from 'react-router-dom';


let test = {
  // border:"1px solid red"
}



function Dashboard() {

  const isXS = useMediaQuery('xs')
  
  const user = getUser();
  const token = getToken();

  const [mydocuments, setMydocuments] = React.useState(null);
  const [newresume, setNewresume] = React.useState(null);
  const [newvacancy, setNewvacancy] = React.useState(null);
  const [subscribe, setSubscribe] = React.useState(null);
  // const [subtype, setSubtype] = React.useState('РЕЗЮМЕ');
  // const [subresult, setSubresult] = React.useState('');


  const getAllMyDoc = () => {
    axios.get(`https://cepjobs.kse.ua/api/mydocuments?user=${user}`,
    {
      headers: {'Authorization': `${token}`}
    })
    .then(response => {
      response.data.forEach(d => {
        d.date = d.dateCreate.replace(/T.+/,'')
      });
      setMydocuments(response.data);
    }).catch(error => {
    });
  }
  
  React.useEffect(() => {
    getAllMyDoc()
  }, []);


  React.useEffect(() => {
    axios.get(`https://cepjobs.kse.ua/api/subscribe?user=${user}`,
    {
      headers: {'Authorization': `${token}`}
    })
    .then(response => {
      // console.log('1',response.data)
      setSubscribe(response.data)
    }).catch(error => {});
  }, []);


  const handler = event => {
    setSubscribe(event.target.checked)
  }
  

  React.useEffect(() => {
    if(subscribe!=null){
      axios.get(`https://cepjobs.kse.ua/api/subscribe2?user=${user}&subscribe=${subscribe}`,
      {
        headers: {'Authorization': `${token}`}
      })
      .then(response => {
        // console.log('2',response.data)
        // if(response.data)setSubscribe(true)
      }).catch(error => {});
    }
    
  }, [subscribe]);



  React.useEffect(() => {
    if(newvacancy){
      // console.log('newvacancy',newvacancy)
      let formData = new FormData()
      formData.append('file', newvacancy.image)
      for ( let d in newvacancy.data ) {
        formData.append(d, newvacancy.data[d]);
      }
      console.log('formData',formData)
      axios.post('https://cepjobs.kse.ua/api/addvacancy', formData,
      {
        headers: {'Authorization': `${token}`}
      }).then(response => {
        getAllMyDoc()
      }).catch(error => {});
    
      getAllMyDoc()
      
    }
  }, [newvacancy]);
  

  
  
    

  React.useEffect(() => {
    newresume&&axios.post(`https://cepjobs.kse.ua/api/addresume`, newresume,
    {
      headers: {'Authorization': `${token}`}
    })
    .then(response => {
      getAllMyDoc()
    }).catch(error => {});
  }, [newresume]);





  const renderPosition = (_, rowData) => {
    // console.log('rowData',rowData)
    return (
      <Description title={<Text>{rowData.position.slice(0,isXS?15:75)}</Text>} content={
        <Dot type={!rowData.approved||rowData.approved=='false'?"warning":'success'}>
          <Text style={{ color: '#ccc',textTransform: "uppercase" }} font="10px"> {!rowData.approved||rowData.approved=='false'?"На перевірці":'Перевірено'}</Text>
        </Dot> 
      } />
    )
  }
  
  const renderStatus = (_, rowData) => {
    // console.log('rowData',rowData)
    return (
      <Description title={rowData.type} content={
        <Text span style={{ color: '#ccc' }}> 
          {rowData.documentId}
        </Text>
      } />
    )
  }
  
  
  const renderDelete = (_, rowData) => {
    const removeHandler = () => {
      // console.log('!',rowData.documentId)
      setMydocuments(mydocuments.filter(d => d.documentId !== rowData.documentId))
      axios.post(`https://cepjobs.kse.ua/api/deleteresume`,{user,documentId:rowData.documentId})
      .then(response => {
        // console.log('deleteresume',response)
        // allresume()
      }).catch(error => {
        // console.log(error);
      });
    }
    return (
      <Popover placement="top" content={
        <div style={{ padding: '0 10px' }}>
        <Button onClick={removeHandler} type="error" ghost auto scale={0.7}>Видалити?</Button>
      </div>
      }>
        <Trash2 className='trash2'/>
      </Popover>
    )
  }
  
  const renderExternalLink = (_, rowData) => {
    // console.log('rowData',rowData)
    return (
     
      <NavLink to={rowData.type=="РЕЗЮМЕ"?`/resume?id=${rowData.documentId}`:`/vacancy?id=${rowData.documentId}`}>
        <ExternalLink color="#333" className='exlink'/>            
      </NavLink>
    )
  }



  console.log('Dash:',mydocuments,newvacancy,newresume)

  return (
    <>
    <Header/>
    
    <Spacer h={2}/>
    
    <Grid.Container justify="center" style={test}>
        <Grid xs={23} sm={18} md={16} style={test} direction="column" alignItems="center">

          {mydocuments&&mydocuments.length>0?
          <Table hover={false} data={mydocuments} onChange={value => setData(value)}>
            <Table.Column prop="position" label="Назва/статус" render={renderPosition}/>
            <Table.Column prop="status" label="Тип" render={renderStatus}/>
            <Table.Column prop="date" label="Створено" />
            <Table.Column prop="views" label="Перегляди*" />
            <Table.Column prop="delete" label="" render={renderDelete}/>
            <Table.Column prop="link" label=""  render={renderExternalLink}/>
          </Table>
          :mydocuments&&mydocuments.length<1?<><Text style={{textAlign:'center'}}>У вас поки немає власних резюме/вакансій. <br />Скористайтеся формою нижче, щоб створити перші</Text><ArrowDown/></>:<><Spacer h={3.2}/><Loading /><Spacer h={2}/></>}
        </Grid>
        <Spacer h={1}/>
        
        <Text small style={{ color: '#ccc' }}>* Лічильник переглянутих на сайті ваших документів, починаючи з лютого 2024 року (лише ви маєте доступ до ццієї інформації)</Text>
    </Grid.Container>

    <Spacer h={1}/>
    <Divider />
    <Spacer h={1}/>
    
    <Grid.Container justify="center" style={test}>
        <Grid xs={23} sm={18} md={16} style={test} direction="row" alignItems="center">
          <Text small>Підписатися на розсилку мою пошту нових РЕЗЮМЕ та ВАКАНСІЙ на сайті</Text>
          <Spacer w={.5}/>
          <Checkbox checked={subscribe} onChange={handler}>Так</Checkbox>
        </Grid>
    </Grid.Container>
    
    <Spacer h={2}/>





    <Grid.Container style={{background:'#ece7f2'}} justify="center" direction="row" alignItems="center">
      <Spacer h={2}/>
      <Grid xs={23} sm={16} md={12} direction="column" >
      <Spacer h={2}/>
      <Fieldset.Group value="Створити вакансію">
        
        <Fieldset label="Створити вакансію">
         <VacancyAdd setNewvacancy={setNewvacancy}/>
        </Fieldset>
        
        <Fieldset label="Створити резюме">
          <ResumeAdd setNewresume={setNewresume} />
        </Fieldset>
        
        </Fieldset.Group>
        <Spacer h={8}/>
        

      </Grid> 
    </Grid.Container>


    </>
  );
}



export default Dashboard;
