import React from 'react';
// import Header from './Header';
import { getUser } from './Utils/Common';
import { Radio,Textarea,Divider,Select, Dot,Checkbox,Modal,Text,Input,Spacer,Button,Fieldset } from '@geist-ui/core'
// import axios from 'axios';
import uniqid from 'uniqid';
// import queryString from 'query-string';
import { useForm, Controller } from "react-hook-form";

let test = {border:"1px solid grey"}


function ResumeAdd(props) {

  const user = getUser();
  // const token = getToken();
  const[agree,setAgree] = React.useState(false)

  const { control, register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      type: 'РЕЗЮМЕ',
      dateCreate: new Date(),
      documentId:uniqid(),
      user:user,
      approved:false

    }
  });
  const onSubmit = data => {
    // console.log(data);
    data.experience = data.experience?.replace(/\n+/g,'\n')
    data.skills = data.skills?.replace(/\n+/g,'\n')
    data.achievement = data.achievement?.replace(/\n+/g,'\n')
    
    setModal(true)
    goToTop()
    props.setNewresume(data)
  };



  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  const [modal, setModal] = React.useState(false)
  const closeHandler = (event) => {
    setModal(false)
    // console.log('closed')
  }

 
  // console.log('ResumeAdd:',agree)

  return (
    <>
          
          <Fieldset.Subtitle>
          <Text h3>Ваше ім'я та прізвище</Text>
          <Input type="secondary" width='95%' {...register("name", { required: true })} 
           placeholder="Ваше ім'я та прізвище" name="name"  /><br />
          {errors.name ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>



          <Text h3><p style={{marginRight:'40px'}}>Ваша роль в сфері закупівель</p></Text>
          <p style={{marginRight:'40px'}}>
           Можна вибрати не більше 3 ролей одночасно
          </p>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
              <Select style={{border:'1px solid #555'}} {...field} multiple width="88%" placeholder="Виберіть свою роль в сфері закупівель" >
                    {
                  roles.map((d,index)=>(
                    <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                  ))
                }   
              </Select>
            }
          /><br />
           {errors.role ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          
          
          <Text h3>Сектор закупівель:</Text>
          <Controller
            name="sector"
            control={control}
            rules={{ required: true }}
            style={{paddingRight:'50px'}}
            render={({ field }) => 
              <Radio.Group {...field} ref={null} >
                <Radio value="Публічний">
                  <Text type="secondary" small>Публічний</Text>
                </Radio>
                <Radio value="Комерційний">
                  <Text type="secondary" small>Комерційний</Text>
                </Radio>
                <Radio value="Некомерційний">
                  <Text type="secondary" small>Некомерційний</Text>
                  <Radio.Description><Text small>Громадські організації, благодійні фонди тощо</Text></Radio.Description>
                </Radio>
                <Radio value="Всі сектори">
                  <Text type="secondary" small>Всі сектори</Text>
                </Radio>
              </Radio.Group>
            }
            />
          {errors.sector ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}

          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>



          <Text h3>Рівень посади:</Text>
          <Controller
            name="position"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
              <Radio.Group {...field} ref={null} style={{marginRight:'50px'}}>

                <Radio value="Початковий рівень">
                  <Text type="secondary" small>Початковий рівень</Text>
                  <Radio.Description><Text small>Досвід менше 1 року (молодший спеціаліст, молодший баєр, та інші)</Text></Radio.Description>
                </Radio>
                
                <Radio value="Просунутий рівень">
                  <Text type="secondary" small>Просунутий рівень</Text>
                  <Radio.Description><Text small>Досвід від 1 року (категорійни менеджер, менеджер із закупівель, та інші)</Text></Radio.Description>
                </Radio>

                <Radio value="Керівний рівень">
                  <Text type="secondary" small>Керівний рівень</Text>
                  <Radio.Description><Text small>Досвід на керівних посадах від 1 року (керівник відділу закупівель, СРО, керівник відділу планування та інші)</Text></Radio.Description>
                </Radio>

              </Radio.Group>
            }
            />
          {errors.position ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}

          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>



          <Text h3>Бажана зарплата</Text>
          <Controller
            name="salary"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
            <Select style={{border:'1px solid #555'}} {...field} width="88%" placeholder="Виберіть розмір бажаної зп">
               <Select.Option value="20-30">20000 - 30000 грн</Select.Option>
               <Select.Option value="40-40">30000 - 40000 грн</Select.Option>
               <Select.Option value="40-50">40000 - 50000 грн</Select.Option>
               <Select.Option value="50+">50000 грн та вище</Select.Option>
            </Select>
                }
          /><br />
           {errors.salary ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>




          <Text h3>Вид зайнятості/ Формат роботи</Text>
          <Controller
            name="format"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
            <Select style={{border:'1px solid #555'}} {...field} multiple width="88%" placeholder="Оберіть один або декілька варіантів" >
                  <Select.Option value="100% офлайн">100% офлайн</Select.Option>
                  <Select.Option value="змішаний формат (офіс+віддалено)">змішаний формат (офіс+віддалено)</Select.Option>
                  <Select.Option value="100% віддалений">100% віддалений</Select.Option>
                  <Select.Option value="готовність до релокації в інше місто">готовність до релокації в інше місто</Select.Option>
                  <Select.Option value="готовність до відряджень">готовність до відряджень</Select.Option>
                </Select>
                }
          /><br />
          {errors.format ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
            
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>Виберіть область</Text>
          <Controller
            name="region"
            control={control}
            rules={{ required: true }}
            render={({ field }) => 
            <Select style={{border:'1px solid #555'}} {...field} width="88%" placeholder="Виберіть область">
              {
                region.map((d,index)=>(
                  <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                ))
              }            
            </Select>
                }
          /><br />
           {errors.region ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          <Text h3>Населений пункт</Text>
          <Input type="secondary" width='95%' placeholder="Вкажіть населений пункт" name="city"
          {...register("city", { required: true })} /><br />
           {errors.city ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}

          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>Досвід роботи</Text>
          <p style={{marginRight:'40px'}}>
            Рекомендуємо останнє місце розписати більш детально, попередні місця - одне два речення, рекомендуємо виділяти досягнення з вимірювальними результатами "збільшив кількість постачальників у пріоритетній категорії у 5 разів"
          </p>
          <Textarea type="secondary" height="100px" width='91%' placeholder="Досвід роботи" name="experience"
          {...register("experience", { required: true })} /><br />
           {errors.experience ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>Навички та інструменти</Text>
          <p style={{marginRight:'40px'}}>
            Майданчики, на яких працюєте, ERP системи
          </p>
          <Textarea type="secondary" height="100px" width='91%' placeholder="Досвід роботи" name="skills"
          {...register("skills", { required: true })} /><br />
           {errors.skills ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

           
          <Text h3>Досягнення</Text>
          <p style={{marginRight:'20px'}}>
            Рекомендуємо виділяти досягнення з вимірювальними результатами "збільшив кількість постачальників у пріоритетній категорії у 5 разів", "економія до 15% витрат підприємства через ефективну організацію закупівель". Питання опціональне.
          </p>
          <Textarea type="secondary" height="100px" width='91%' placeholder="Досягнення" name="achievement"
          {...register("achievement", { required: true })} /><br />
          {errors.achievement ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
         
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          <Text h3>Знання мов</Text>
          <p style={{marginRight:'20px'}}>
            Наприклад, українська - вільно, англійська - просунутий.
          </p>
          <Input type="secondary" width='95%' placeholder="мови" name="languages"
          {...register("languages", { required: true })} /><br />
           {errors.languages ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>
          
          <Text h3>Вища освіта</Text>
          <p style={{marginRight:'20px'}}>
           Наприклад: Магістр, Корпоративне право, Національний авіаційний університет, 2015 рік.
          </p>
          <Input type="secondary" width='95%' placeholder="освіта" name="education"
          {...register("education", { required: true })} /><br />
           {errors.education ? <Text type="error" small>Будь ласка, заповніть поле</Text>:<Text type="warning"  small>Це поле має бути обов'язково заповнене</Text>}
           <Spacer h={1}/>
           
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>


          <Text h3><p style={{marginRight:'20px'}}>Курси підвищення кваліфікації в Центрі вдосконалення закупівель Київської школи економіки</p></Text>
          <Controller
            name="cep"
            control={control}
            // rules={{ required: true }}
            render={({ field }) => 
              <Select style={{border:'1px solid #555'}} {...field} multiple width="88%" placeholder="Виберіть, якщо проходили один або декілька варіантів" >
                    {
                  corses.map((d,index)=>(
                    <Select.Option key={index} index={index} value={d}>{d}</Select.Option>
                  ))
                }   
              </Select>
            }
          /><br />
          <Spacer h={1}/>
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>


          <Text h3>Інші курси підвищення кваліфікації</Text>
          <p style={{marginRight:'20px'}}>
            Назва курсу, назва організації надавача послуг, рік завершення.
          </p>
          <Input type="secondary" width='95%' placeholder="курси" name="courses"
          {...register("courses")} /><br />
          <Spacer h={1}/>


          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>

          {/* <Text h3>Волонтерська діяльність</Text>
          <Input type="secondary" width='95%' placeholder="волонтерство" name="volunteer"
          {...register("volunteer")} /><br />
          <Spacer h={1}/>
          
          <Divider h={10} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/> */}

          <Text h3>Про себе</Text>
          <Input type="secondary" width='95%' placeholder="Додаткова інформація" name="hobby"
          {...register("hobby")} /><br />
          <Spacer h={1}/>
          
          <Divider h={15} style={{marginLeft:'-20.5px',background:'#ece7f2'}}/>




          {<Button style={{width:'90%',marginTop:'30px'}} type="secondary" 
          onClick={handleSubmit(onSubmit)} 
          // onClick={onSubmit} 
          disabled={!agree||false} >Подати</Button>}
        
          <Modal visible={modal} onClose={closeHandler}>
            <Modal.Title>Резюме додано!</Modal.Title>
            {/* <Modal.Subtitle>Резюме додано!</Modal.Subtitle> */}
            <Modal.Content>
              <p>Залишилося ще зовсім трохи, Ваше резюме буде <Dot type="warning" /> перевірено Центром вдосконалення закупівель, і ми одразу Вам повідомимо про результат. Тоді резюме зможуть побачити на сайті усі відвідувачі</p>
            </Modal.Content>
            <Modal.Action passive onClick={() => setModal(false)}>Ok</Modal.Action>
            {/* <Modal.Action>Submit</Modal.Action> */}
          </Modal>


          </Fieldset.Subtitle>

          <Spacer h={1}/>
          <Fieldset.Footer style={{marginLeft:'-20.5px'}}>
            <Checkbox onChange={value => {
              setAgree(value.target.checked)
            }} checked={false}>
              <Text small>
                Згода на обробку і публікацію, оприлюднення всіх вказаних даних
              </Text>
            </Checkbox>
          </Fieldset.Footer>
          <Spacer h={1}/>
         
     

    </>
  );
}

let region = [
  "Вінницька",
  "Волинська",
  "Дніпропетровська",
  "Донецька",
  "Житомирська",
  "Закарпатська",
  "Запорізька",
  "Івано-Франківська",
  "Київська",
  "Кіровоградська",
  "Луганська",
  "Львівська",
  "Миколаївська",
  "Одеська",
  "Полтавська",
  "Рівненська",
  "Сумська",
  "Тернопільська",
  "Харківська",
  "Херсонська",
  "Хмельницька",
  "Черкаська",
  "Чернівецька",
  "Чернігівська"
]


let corses = [
  "Закупівельний менеджмент",					
  "Менеджер із закупівель",						
  "Фахівець з публічних закупівель",						
  "Підготовка до тестування УО",						
  "Програма професіоналізації закупівель",						
  "Тренінг для тренерів",						
  "Зелені публічні закупівлі"
]


let roles = [
  "Менеджер із закупівель",
  "Фахівець з публічних закупівель",
  "Уповноважена особа",
  "Експерт із закупівель",
  "Спеціаліст з тендерних закупівель",
  "Категорійний менеджер",
  "Фахівець / фахівчиня із закупівель",
  "Керівник логістичного центру",
  "Керівник категорійного відділу",
  "Провідний фахівець із закупівель",
  "Фахівець з договірної роботи",
  "Менеджери ЗЕД",
  "Керівник відділу закупівель та постачання",
  "Менеджери з закупівель та постачання, проєктні менеджери",
  "Директори із закупівель, керівники відділів"
]
  
export default ResumeAdd;
