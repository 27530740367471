import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import Header from './Header';
import { Grid,Themes, Spacer,Text,Breadcrumbs ,CssBaseline,Divider,GeistProvider} from '@geist-ui/core'

const myTheme1 = Themes.createFromLight({
  type: 'coolTheme',
  palette: {
    success: '#1d91c0',
  },
})

let test = {
// border:"1px solid #eee",
// padding:"20px"
}


function Blog2() {
  
  // console.log('Blog1:')


  return (
    <div className="App">
     <Header/>
      <Spacer h={2}/>
      

      <Grid.Container  justify="center" >
        <Grid xs={22} sm={12} md={12}  direction="column">

        <Breadcrumbs>
          <Breadcrumbs.Item href="/">Головна</Breadcrumbs.Item>
          <Breadcrumbs.Item>Блог</Breadcrumbs.Item>
          <Breadcrumbs.Item>Які питання ставити закупівельнику на співбесіді</Breadcrumbs.Item>
        </Breadcrumbs>
        <div style={test}>
        <GeistProvider themes={[myTheme1]} themeType="coolTheme">
        <CssBaseline />
        <Text h1>Які питання ставити закупівельнику на співбесіді</Text>
        
        

<Text p>Закупівлі - це дуже інтелектуально ємна професія, яка передбачає і вимагає дуже специфічних навичок і досвіду. Закупівельники мають володіти знаннями в технічній, юридичній, аналітичній сферах, вміти аналізувати дані і ринки, передбачати ризики, комунікувати з колегами, ініціаторами закупівель, всіма службами компанії, постачальниками і конкурентами, проводити переговори, впроваджувати цифрові рішення. В кар'єрі успішного закупівельника варті уваги одночасно hard і soft навички, тому провести співбесіду на роль в закупівельну команду - це нелегке завдання. В блозі нижче ми пропонуємо кілька підказок роботодавцям, як побудувати професійне інтерв'ю з кандидатами на посади в закупівлях.</Text>
<Text p>Готуючись до співбесіди для спеціаліста із закупівель пропонуємо Вам врахувати наступні теми і питання: </Text> 

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>Знання закупівельного процесу</Divider>
<Spacer h={1}/>
<Text p> Розпочати співбесіду із кандидатом варто із перевірки знань щодо загальної організації закупівельного процесу, розуміння ролі закупівельного відділу в компанії, його місця в організаційній структурі і точок співпраці з іншими відділами.  </Text>
<Text p>Приклади питань, які можна поставити кандидатам:</Text>

<Text p>• Які ключові етапи має процес закупівель?</Text>
<Text p> • Яке місце займає закупівельна функція в організаційні структурі підприємства?</Text> 
<Text p>• Які служби і департаменти компанії співпрацюють із закупівельниками і на яких етапах?</Text> 
<Text p>• Які інструменти ви використовуєте для відстеження прогресу ваших проектів?</Text>
<Text p>• Які методи ви використовуєте, щоб прискорити прогрес у ваших закупівлях?</Text>
<Text p>• Що таке заявка на закупівлю, і як би Ви її обробили?</Text>
<Text p>• Чи можете ви пояснити процес закупівлі крок за кроком?</Text>
<Text p>• Які ключові показники закупівель Ви можете назвати?</Text>
<Text p>• Чи маєте досвід організації/здійснення тендерних закупівель?</Text> 

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>Аналіз закупівель і Економія бюджетів на закупівлю  
</Divider>
<Spacer h={1}/>
<Text p>Дуже часто в своїй роботі закупівельники мають дбати про економію бюджетів на закупвлі і навіть мають відповідні KPI від керівництва компанії. Для управління витратами важливо мати навички аналітики даних з використанням відповідних інструментів (Microsoft Excel, BI Prozorro (для публічних закупівель), вміти робити АВС-аналіз категорій і постачальників, вміти рахувати сукупну вартість володіння TCO для довгострокових проєктів закупівель, а також приймати рішення на основі даних. </Text>

<Text p>Приклади питань, які можна поставити кандидатам:</Text>

<Text p>• Які підходи ви можете запропонувати до аналізу витрат компанії?</Text>
<Text p>• Чи можна побачити ефективну роботу закупівельників в фінансових результатах компанії? </Text>
<Text p>• Які фактори варто врахувати для досягнення економії коштів за короткий проміжок часу?</Text>
<Text p>• Як пріоритезувати закупівлі компанії? </Text>
<Text p>• Які би ви запровадили зміни в масштабах усієї компанії, щоб заощадити кошти, і як отримати підтримку?</Text>
<Text p>• Що б ви зробили, якщо відділ захоче замовити обладнання, яке виходить за межі бюджету?</Text>
<Text p>• Чи знаєте Ви про сукупну вартість володіння ТСО і чи використовуєте цей інструмент в роботі?</Text>
<Text p>• Як ви комунікуєте питання економії коштів, щоб їх сприйняло керівництво?</Text>

<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>Аналіз ринку і Пошук постачальників
</Divider>
<Spacer h={1}/>
<Text p>Якісний аналіз ринку - дуже важлива частина роботи закупівельника, тому в співбесідах на закупівельні ролі варто приділити цьому час і кілька окремих питань. Важливо перевірити у кандидата розуміння сили закупівельника і постачальника, а також факторів, які впливають на формування ланцюга постачання, і відповідно, на ціну. Також важливо, щоб кандидат розумів, як перевірити постачальника перед початком співпраці. </Text>

<Text p>Приклади питань, які можна поставити кандидатам:</Text>

<Text p>• Які фактори визначають силу попиту/ пропозиції?</Text>
<Text p>• Як  дізнатися, чи справедлива ціна на товар?</Text>
<Text p>• Через який процес ви проходите під час пошуку постачальників?</Text>
<Text p>• Яку роль грає перевірка постачальників у закупівельному процесі? </Text>
<Text p>• Де і як перевірити українських або закордонних постачальників?</Text>


<Spacer h={2}/>
<Divider style={{background:'#1d91c0'}}>Робота з постачальниками і Переговори
</Divider>
<Spacer h={1}/>
<Text p>Для забезпечення сталих закупівель закупівельнику потрібно вміти управляти стосунками з постачальниками, будувати довіру, вести базу даних постачальників і вміти комунікувати з ними на різних етапах закупівельного циклу. Крім того, закупівельнику також важливо знати, як підготуватись до різних видів і рівнів переговорів,  вміти провести переговори, аргументувати свою позицію і слухати позицію опонента. </Text>

<Text p>Приклади питань, які можна поставити кандидатам:</Text>

<Text p>• Чи використовували ви програмне забезпечення для керування постачальниками?</Text>
<Text p>• Які питання ви можете поставити, запитуючи рекомендації постачальника?</Text>
<Text p>• Як підготуватись до переговорів? </Text>
<Text p>• Як ви ведете переговори зі складним постачальником?</Text>
<Text p>• Опишіть свої навички та тактику ведення переговорів.</Text>
<Text p>• Що змусить вас шукати нового постачальника?</Text>

<Text p>Крім окреслених вище тем, в залежності від специфіки роботи, роботодавець може продумати й підготувати на співбесіду й інші питання: про контрактинг, логістику, складське господарство, управління залишками, категорійний менеджмент, управління ризиками. Головне, не пропустити справжні таланти і майбутніх лідерів закупівель! </Text>

</GeistProvider>
</div>

        </Grid>
       
        </Grid.Container>


    </div>
  );
}

export default Blog2;
