import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import axios from 'axios';
import './css/App.css';

import Footer from './Footer';
import Savetoken from './Savetoken';
import Register from './Register';
import Dashboard from './Dashboard';
import Home from './Home';
import Resume from './Resume';
import Vacancy from './Vacancy';
import Error from './Error';

import About from './About';
// import Admin from './Admin';

import Ugoda from './Ugoda';

import Blog1 from './Blog1';
import Blog2 from './Blog2';
import Blog3 from './Blog3';

import Allresume from './Allresume';
import Allvacancy from './Allvacancy';

// import "@fontsource/roboto"; // Defaults to weight 400.

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';

import { getToken, getUser,removeUserSession, setUserSession } from './Utils/Common';


function App() {
  // const [authLoading, setAuthLoading] = useState(true);

  // removeUserSession();

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYS5ncmliYW5vdnNraXlAZ21haWwuY29tIiwiaWF0IjoxNjczNDI2NDU0LCJleHAiOjE2NzM0MzAwNTR9.ztcQrL96WFxEHR2htrMgRjS8ClSxzC_sv3o5pNgfu8g1'
    axios.get(`https://cepjobs.vercel.app/api/verify`,
    {
      headers: {
        'Authorization': `${token}`
      }
    })
    .then(response => {
      // setUserSession(response.data.token, response.data.user, response.data.user.paid);
      // console.log('APP_ ',response)
      // setAuthLoading(false);
    }).catch(error => {
      removeUserSession();
      // setAuthLoading(false);
    });
  }, []);


  
  // console.log('APP:',resume)

  // if (authLoading && getToken()) {
  //   return <Spinner />
  // }

  return (
    <div className="App">


      <BrowserRouter>
      {/* <Header/> */}
      <div className="content">
            <Switch>
              
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/ugoda" component={Ugoda} />
              <Route path="/jak-pіdgotuvatisja-do-spіvbesіdi" component={Blog1} />
              <Route path="/jakі-pitannja-staviti-zakupіvelniku" component={Blog2} />
              <Route path="/jak-napisati-suprovіdnij-list" component={Blog3} />
              <Route path="/resume" component={Resume}/>
              <Route path="/vacancy" component={Vacancy}/>
              <Route path="/savetoken" component={Savetoken} />
              <Route path="/allresume" component={Allresume} />
              <Route path="/allvacancy" component={Allvacancy} />
              {/* <Route path="/admin" component={Admin} /> */}

              <PublicRoute path="/register" component={Register} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              
              <Route path='/*' component={Error}/>
              
            </Switch>
          </div>
          <Footer />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
